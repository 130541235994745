import React, { useState } from "react";
import { Box, Stack, IconButton, useTheme, useMediaQuery, Typography } from "@mui/material";
import StyledTypography from "@/components/StyledComponents/EllipseTypography";
import { BasketballLiveDataTypeFragment } from "@/components/Common/GameLiveData/Fragments/BasketballLiveDataTypeFragment";
import formatSeconds from "@/utis/formatGameSeconds";
import SensorsIcon from "@mui/icons-material/Sensors";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useTranslations } from "next-intl";
import ScoreIndicatorLiveData from "../ScoreIndicatorLiveData";
import { formatPeriod, isGameFinished } from "../Utils";
import { LiveDataDesign } from "../DesignEnum";

type DefaultBasketballDesignProps = {
    gameLiveData: BasketballLiveDataTypeFragment;
    awayTeamAbbr?: string;
    homeTeamAbbr?: string;
};

const views = ["default", "fouls", "threePointers", "twoPointers", "freeThrows", "freeThrowsMissed", "freeThrowsPending"] as const;
type ViewType = typeof views[number];

function DetailedBasketballDesign({ gameLiveData, awayTeamAbbr, homeTeamAbbr }: DefaultBasketballDesignProps) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const [currentView, setCurrentView] = useState<ViewType>("default");
    const t = useTranslations("liveData");
    const {
        homeTeamTotalPointsByQuarter = [0],
        awayTeamTotalPointsByQuarter = [0],
        period = "",
        gameTimeSeconds = 0,
        homeTeamHasPossession = false,
        additionalData = "",
        homeTeamFoulsByQuarter = [0],
        awayTeamFoulsByQuarter = [0],
        homeTeamThreePointersByQuarter = [0],
        awayTeamThreePointersByQuarter = [0],
        homeTeamTwoPointersByQuarter = [0],
        awayTeamTwoPointersByQuarter = [0],
        homeTeamFreeThrowsScoredByQuarter = [0],
        awayTeamFreeThrowsScoredByQuarter = [0],
        homeTeamFreeThrowsMissedByQuarter = [0],
        awayTeamFreeThrowsMissedByQuarter = [0],
        homeTeamFreeThrowsPendingByQuarter = [0],
        awayTeamFreeThrowsPendingByQuarter = [0],
        isAtTimeout
    } = gameLiveData;

    const homeTeamScore = homeTeamTotalPointsByQuarter?.reduce((acc, curr) => acc + curr, 0)?.toString();
    const awayTeamScore = awayTeamTotalPointsByQuarter?.reduce((acc, curr) => acc + curr, 0)?.toString();
    const formattedGameTime = formatSeconds(gameTimeSeconds);

    const gameFinished = isGameFinished(period);
    const formattedPeriod = formatPeriod(period);



    const handleViewChange = (direction: "next" | "prev") => {
        const currentIndex = views.indexOf(currentView);
        const newIndex = direction === "next" ? (currentIndex + 1) % views.length : (currentIndex - 1 + views.length) % views.length;
        setCurrentView(views[newIndex]);
    };

    const getViewTitle = () => {
        switch (currentView) {
            case "default":
                return t("scores_by_quarter");
            case "fouls":
                return t("fouls");
            case "threePointers":
                return t("three_pointers");
            case "twoPointers":
                return t("two_pointers");
            case "freeThrows":
                return t("free_throws");
            case "freeThrowsMissed":
                return t("free_throws_missed");
            case "freeThrowsPending":
                return t("free_throws_pending");
            default:
                return "";
        }
    };

    const getTotal = (data: number[] | null) => (data ?? []).reduce((a, b) => a + b, 0);

    const getViewData = () => {
        switch (currentView) {
            case "default":
                return {
                    awayData: awayTeamTotalPointsByQuarter,
                    homeData: homeTeamTotalPointsByQuarter,
                    awayScore: awayTeamScore,
                    homeScore: homeTeamScore,
                };
            case "fouls":
                return {
                    awayData: awayTeamFoulsByQuarter,
                    homeData: homeTeamFoulsByQuarter,
                    awayScore: getTotal(awayTeamFoulsByQuarter),
                    homeScore: getTotal(homeTeamFoulsByQuarter),
                };
            case "threePointers":
                return {
                    awayData: awayTeamThreePointersByQuarter,
                    homeData: homeTeamThreePointersByQuarter,
                    awayScore: getTotal(awayTeamThreePointersByQuarter),
                    homeScore: getTotal(homeTeamThreePointersByQuarter),
                };
            case "twoPointers":
                return {
                    awayData: awayTeamTwoPointersByQuarter,
                    homeData: homeTeamTwoPointersByQuarter,
                    awayScore: getTotal(awayTeamTwoPointersByQuarter),
                    homeScore: getTotal(homeTeamTwoPointersByQuarter),
                };
            case "freeThrows":
                return {
                    awayData: awayTeamFreeThrowsScoredByQuarter,
                    homeData: homeTeamFreeThrowsScoredByQuarter,
                    awayScore: getTotal(awayTeamFreeThrowsScoredByQuarter),
                    homeScore: getTotal(homeTeamFreeThrowsScoredByQuarter),
                };
            case "freeThrowsMissed":
                return {
                    awayData: awayTeamFreeThrowsMissedByQuarter,
                    homeData: homeTeamFreeThrowsMissedByQuarter,
                    awayScore: getTotal(awayTeamFreeThrowsMissedByQuarter),
                    homeScore: getTotal(homeTeamFreeThrowsMissedByQuarter),
                };
            case "freeThrowsPending":
                return {
                    awayData: awayTeamFreeThrowsPendingByQuarter,
                    homeData: homeTeamFreeThrowsPendingByQuarter,
                    awayScore: getTotal(awayTeamFreeThrowsPendingByQuarter),
                    homeScore: getTotal(homeTeamFreeThrowsPendingByQuarter),
                };
            default:
                return {
                    awayData: [],
                    homeData: [],
                    awayScore: 0,
                    homeScore: 0,
                };
        }
    };

    const { awayData, homeData, awayScore, homeScore } = getViewData();

    return (
        <Stack pb={isMobile ? 0 : 2} px={isMobile ? 0 : 2} gap={1} >
            <Stack direction="row" alignItems="center" justifyContent="space-between">

                {
                    gameFinished ? (
                        <Stack alignItems="center" direction="row" gap={0.5} >
                            <Typography color="text.secondary" variant="label" textAlign="left">
                                FINAL
                            </Typography>
                        </Stack>
                    ) : (
                        <Stack alignItems="center" direction="row" gap={0.5} >
                            <SensorsIcon fontSize="small" color={isAtTimeout || gameFinished ? "disabled" : "error"} />
                            <StyledTypography variant="body2" color="text.secondary">
                                Live Data
                            </StyledTypography>
                        </Stack>
                    )
                }

                <Stack direction="row" alignItems="center" pl={2}>
                    {
                        !gameFinished && (
                            <StyledTypography variant="caption" color="text.primary" fontWeight="bold">
                                {formattedPeriod} {formattedGameTime}
                            </StyledTypography>
                        )
                    }
                </Stack>


                <Stack direction="row" alignItems="center">
                    {
                        !isMobile && (
                            <StyledTypography
                                variant="caption"
                                color="text.secondary"
                                sx={{ width: 120, textAlign: "end" }}
                            >
                                {getViewTitle()}
                            </StyledTypography>
                        )
                    }
                    <IconButton onClick={() => handleViewChange("prev")} sx={{ py: 0 }}>
                        <KeyboardArrowLeftIcon />
                    </IconButton>
                    <IconButton onClick={() => handleViewChange("next")} sx={{ py: 0 }}>
                        <KeyboardArrowRightIcon />
                    </IconButton>
                </Stack>
            </Stack>

            <Stack direction="row" alignItems="start">

                <Stack direction="row" alignItems="center" width={isMobile ? 205 : 340}>

                    <Stack
                        direction="column"
                        spacing={0}
                        sx={{
                            borderRight: `0.5px solid ${theme.palette.border.primary}`,
                            pr: 1,
                            flexShrink: 0,
                        }}
                    >
                        {/* Away team row */}
                        <Stack direction="row" spacing={1} alignItems="center">
                            {/* Team indicator with fixed width */}
                            <Box width={isMobile ? 52 : 176} flexShrink={0}>
                                <ScoreIndicatorLiveData
                                    teamName={awayTeamAbbr}
                                    isServing={!homeTeamHasPossession}
                                    design={LiveDataDesign.DETAILED}
                                    isFinished={gameFinished}
                                />
                            </Box>

                            {/* Quarter data points */}
                            <Stack direction="row" spacing={1} justifyContent="flex-end">
                                {Array(4).fill(undefined).map((_, index) => (
                                    <StyledTypography
                                        color="text.primary"
                                        variant="body2"
                                        key={`away-data-${index}`}
                                        sx={{ width: 16, textAlign: 'center' }}
                                    >
                                        {awayData && awayData[index] !== undefined ? awayData[index] : "-"}
                                    </StyledTypography>
                                ))}
                            </Stack>
                        </Stack>

                        {/* Quarter headers row */}
                        <Stack direction="row" spacing={1} alignItems="center">
                            {/* Empty space to align with team names */}
                            <Box width={isMobile ? 52 : 176} flexShrink={0} lineHeight="12px" />

                            {/* Quarter numbers */}
                            <Stack direction="row" spacing={1} justifyContent="flex-end">
                                {["1", "2", "3", "4"].map((header, index) => (
                                    <StyledTypography
                                        color="text.secondary"
                                        variant="label"
                                        key={`header-${index}`}
                                        sx={{ width: 16, textAlign: 'center' }}
                                    >
                                        {header}
                                    </StyledTypography>
                                ))}
                            </Stack>
                        </Stack>

                        {/* Home team row */}
                        <Stack direction="row" spacing={1} alignItems="center">
                            {/* Team indicator with fixed width */}
                            <Box width={isMobile ? 52 : 176} flexShrink={0}>
                                <ScoreIndicatorLiveData
                                    teamName={homeTeamAbbr}
                                    isServing={homeTeamHasPossession}
                                    design={LiveDataDesign.DETAILED}
                                    isFinished={gameFinished}
                                />
                            </Box>

                            {/* Quarter data points */}
                            <Stack direction="row" spacing={1} justifyContent="flex-end">
                                {Array(4).fill(undefined).map((_, index) => (
                                    <StyledTypography
                                        variant="body2"
                                        key={`home-data-${index}`}
                                        sx={{ width: 16, textAlign: 'center' }}
                                    >
                                        {homeData && homeData[index] !== undefined ? homeData[index] : "-"}
                                    </StyledTypography>
                                ))}
                            </Stack>
                        </Stack>

                    </Stack>

                    <Stack direction="column" gap={1.5} alignItems="start">
                        <ScoreIndicatorLiveData score={awayScore?.toString()} design={LiveDataDesign.DETAILED} view={currentView} />
                        <ScoreIndicatorLiveData score={homeScore?.toString()} design={LiveDataDesign.DETAILED} view={currentView} />
                    </Stack>


                </Stack>

                <Box width={isMobile ? 135 : 186}>
                    <StyledTypography
                        lineClamp={4}
                        mt={0.5}
                        color="text.secondary"
                        variant="body2"
                        sx={{
                            lineHeight: isMobile ? "1em" : "1.2em",
                            display: "-webkit-box",
                            WebkitBoxOrient: "vertical",
                            whiteSpace: "normal",
                            opacity: 0.5,
                        }}
                    >
                        {additionalData}
                    </StyledTypography>
                </Box>
            </Stack>
            {
                isMobile && (
                    <Stack direction="row" justifyContent="start" ml="58px">
                        <StyledTypography
                            color="text.secondary"
                            variant="caption"
                            width={110}
                            textAlign="left"
                            lineHeight="1.2em"
                        >
                            {getViewTitle()}
                        </StyledTypography>
                    </Stack>
                )

            }

        </Stack>
    );
}

export default DetailedBasketballDesign;