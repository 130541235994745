export default function formatSeconds(seconds: number) {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  // Pad the minutes and seconds with leading zeros if needed
  const paddedMinutes = String(minutes).padStart(2, "0");
  const paddedSeconds = String(remainingSeconds).padStart(2, "0");

  // Return null if time is "00:00"
  if (paddedMinutes === "00" && paddedSeconds === "00") {
    return null;
  }

  return `${paddedMinutes}:${paddedSeconds}`;
}