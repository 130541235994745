import React, { useState } from "react";
import { Box, Stack, IconButton, useTheme, useMediaQuery } from "@mui/material";
import StyledTypography from "@/components/StyledComponents/EllipseTypography";
import { IceHockeyLiveDataTypeFragment } from "@/components/Common/GameLiveData/Fragments/IceHockeyLiveDataTypeFragment";
import formatSeconds from "@/utis/formatGameSeconds";
import SensorsIcon from "@mui/icons-material/Sensors";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useTranslations } from "next-intl";
import ScoreIndicatorLiveData from "../ScoreIndicatorLiveData";
import { LiveDataDesign } from "../DesignEnum";
import { formatPeriod, isGameFinished } from "../Utils";

type DetailedIceHockeyDesignProps = {
    gameLiveData: IceHockeyLiveDataTypeFragment;
    homeTeamName?: string;
    awayTeamName?: string;
};

const views = ["default", "penaltyCounts", "penaltyMinutes", "penaltyShots"] as const;
type ViewType = typeof views[number];

function DetailedIceHockeyDesign({
    gameLiveData,
    homeTeamName,
    awayTeamName
}: DetailedIceHockeyDesignProps) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const [currentView, setCurrentView] = useState<ViewType>("default");
    const t = useTranslations("liveData");

    const {
        homeTeamGoalsByPeriod = [0],
        awayTeamGoalsByPeriod = [0],
        period = "",
        gameTimeSeconds = 0,
        homeTeamHasPossession = false,
        additionalData = "",
        awayTeamPenalityMinuteByPeriod = [0],
        homeTeamPenalityMinuteByPeriod = [0],
        awayTeamPenalityCountByPeriod = [0],
        homeTeamPenalityCountByPeriod = [0],
        awayTeamPenalityShotByPeriod = [0],
        homeTeamPenalityShotByPeriod = [0],
        isAtTimeout
    } = gameLiveData;

    const homeTeamScore = homeTeamGoalsByPeriod?.reduce((acc, curr) => acc + (curr ?? 0), 0);
    const awayTeamScore = awayTeamGoalsByPeriod?.reduce((acc, curr) => acc + (curr ?? 0), 0);
    const formattedGameTime = formatSeconds(gameTimeSeconds);

    const gameFinished = isGameFinished(period);
    const formattedPeriod = formatPeriod(period);


    const handleViewChange = (direction: "next" | "prev") => {
        const currentIndex = views.indexOf(currentView);
        const newIndex = direction === "next"
            ? (currentIndex + 1) % views.length
            : (currentIndex - 1 + views.length) % views.length;
        setCurrentView(views[newIndex]);
    };

    const getViewTitle = () => {
        switch (currentView) {
            case "default": return t("scores");
            case "penaltyCounts": return t("panelty_counts");
            case "penaltyMinutes": return t("penalty_minutes");
            case "penaltyShots": return t("penalty_shots");
            default: return "";
        }
    };

    const getTotal = (data: number[] | null) => (data ?? []).reduce((a, b) => a + b, 0);

    const getViewData = () => {
        switch (currentView) {
            case "default":
                return {
                    awayData: awayTeamGoalsByPeriod,
                    homeData: homeTeamGoalsByPeriod,
                    awayScore: awayTeamScore,
                    homeScore: homeTeamScore,
                };
            case "penaltyCounts":
                return {
                    awayData: awayTeamPenalityCountByPeriod,
                    homeData: homeTeamPenalityCountByPeriod,
                    awayScore: getTotal(awayTeamPenalityCountByPeriod),
                    homeScore: getTotal(homeTeamPenalityCountByPeriod),
                };
            case "penaltyMinutes":
                return {
                    awayData: awayTeamPenalityMinuteByPeriod,
                    homeData: homeTeamPenalityMinuteByPeriod,
                    awayScore: getTotal(awayTeamPenalityMinuteByPeriod),
                    homeScore: getTotal(homeTeamPenalityMinuteByPeriod),
                };
            case "penaltyShots":
                return {
                    awayData: awayTeamPenalityShotByPeriod,
                    homeData: homeTeamPenalityShotByPeriod,
                    awayScore: getTotal(awayTeamPenalityShotByPeriod),
                    homeScore: getTotal(homeTeamPenalityShotByPeriod),
                };
            default:
                return {
                    awayData: [],
                    homeData: [],
                    awayScore: 0,
                    homeScore: 0,
                };
        }
    };

    const { awayData, homeData, awayScore, homeScore } = getViewData();

    // Define dimensions
    const teamColumnWidth = isMobile ? 52 : 176;
    const containerWidth = isMobile ? 205 : 340;
    const additionalDataWidth = isMobile ? 135 : 186;
    const periodCellWidth = 16;


    return (
        <Stack pb={isMobile ? 1 : 2} px={isMobile ? 1 : 2} gap={1} >
            <Stack direction="row" alignItems="center" justifyContent="space-between">
                {
                    gameFinished ? (
                        <Stack alignItems="center" direction="row" gap={0.5} >
                            <StyledTypography color="text.secondary" variant="label" textAlign="left">
                                FINAL
                            </StyledTypography>
                        </Stack>
                    ) : (
                        <Stack alignItems="center" direction="row" gap={0.5} >
                            <SensorsIcon fontSize="small" color={isAtTimeout || gameFinished ? "disabled" : "error"} />
                            <StyledTypography variant="body2" color="text.secondary">
                                Live Data
                            </StyledTypography>
                        </Stack>
                    )
                }

                <Stack direction="row" alignItems="center" pl={2}>
                    {
                        !gameFinished && (
                            <StyledTypography variant="caption" color="text.primary" fontWeight="bold">
                                {formattedPeriod} {formattedGameTime}
                            </StyledTypography>
                        )
                    }

                </Stack>

                {/* View controls */}
                <Stack direction="row" alignItems="center">
                    {!isMobile && (
                        <StyledTypography
                            variant="caption"
                            color="text.secondary"
                            sx={{ width: 120, textAlign: "end" }}
                        >
                            {getViewTitle()}
                        </StyledTypography>
                    )}
                    <IconButton onClick={() => handleViewChange("prev")} sx={{ py: 0 }}>
                        <KeyboardArrowLeftIcon />
                    </IconButton>
                    <IconButton onClick={() => handleViewChange("next")} sx={{ py: 0 }}>
                        <KeyboardArrowRightIcon />
                    </IconButton>
                </Stack>
            </Stack>

            {/* Main content */}
            <Stack direction="row" alignItems="start">
                <Stack direction="row" alignItems="center" width={containerWidth}>
                    <Stack
                        direction="column"
                        spacing={0}
                        sx={{
                            borderRight: `0.5px solid ${theme.palette.border.primary}`,
                            pr: 1,
                            flexShrink: 0,
                        }}
                    >
                        {/* Away team row */}
                        <Stack direction="row" spacing={1} alignItems="center">
                            {/* Team indicator */}
                            <Box width={teamColumnWidth} flexShrink={0}>
                                <ScoreIndicatorLiveData
                                    teamName={awayTeamName}
                                    isServing={!homeTeamHasPossession}
                                    design={LiveDataDesign.DETAILED}
                                />
                            </Box>

                            {/* Period data points */}
                            <Stack direction="row" spacing={1} justifyContent="flex-end">
                                {Array(3).fill(undefined).map((_, index) => (
                                    <StyledTypography
                                        color="text.primary"
                                        variant="body2"
                                        key={`away-data-${index}`}
                                        sx={{ width: periodCellWidth, textAlign: 'center' }}
                                    >
                                        {awayData && awayData[index] !== undefined ? awayData[index] : "-"}
                                    </StyledTypography>
                                ))}
                            </Stack>
                        </Stack>

                        {/* Period headers row */}
                        <Stack direction="row" spacing={1} alignItems="center">
                            {/* Empty space to align with team names */}
                            <Box width={teamColumnWidth} flexShrink={0} lineHeight="12px" />

                            {/* Period numbers */}
                            <Stack direction="row" spacing={1} justifyContent="flex-end">
                                {["1", "2", "3"].map((header, index) => (
                                    <StyledTypography
                                        color="text.secondary"
                                        variant="label"
                                        key={`header-${index}`}
                                        sx={{ width: periodCellWidth, textAlign: 'center' }}
                                    >
                                        {header}
                                    </StyledTypography>
                                ))}
                            </Stack>
                        </Stack>

                        {/* Home team row */}
                        <Stack direction="row" spacing={1} alignItems="center">
                            {/* Team indicator */}
                            <Box width={teamColumnWidth} flexShrink={0}>
                                <ScoreIndicatorLiveData
                                    teamName={homeTeamName}
                                    isServing={homeTeamHasPossession}
                                    design={LiveDataDesign.DETAILED}
                                />
                            </Box>

                            {/* Period data points */}
                            <Stack direction="row" spacing={1} justifyContent="flex-end">
                                {Array(3).fill(undefined).map((_, index) => (
                                    <StyledTypography
                                        variant="body2"
                                        key={`home-data-${index}`}
                                        sx={{ width: periodCellWidth, textAlign: 'center' }}
                                    >
                                        {homeData && homeData[index] !== undefined ? homeData[index] : "-"}
                                    </StyledTypography>
                                ))}
                            </Stack>
                        </Stack>
                    </Stack>

                    {/* Total scores */}
                    <Stack direction="column" gap={1.5} alignItems="start">
                        <ScoreIndicatorLiveData score={awayScore?.toString()} design={LiveDataDesign.DETAILED} view={currentView} />
                        <ScoreIndicatorLiveData score={homeScore?.toString()} design={LiveDataDesign.DETAILED} view={currentView} />
                    </Stack>
                </Stack>

                {/* Additional data */}
                <Box width={additionalDataWidth}>
                    <StyledTypography
                        lineClamp={4}
                        mt={0.5}
                        color="text.secondary"
                        variant="body2"
                        sx={{
                            lineHeight: isMobile ? "1em" : "1.2em",
                            display: "-webkit-box",
                            WebkitBoxOrient: "vertical",
                            whiteSpace: "normal",
                            opacity: 0.5,
                        }}
                    >
                        {additionalData}
                    </StyledTypography>
                </Box>
            </Stack>

            {/* Mobile view title */}
            {isMobile && (
                <Stack direction="row" justifyContent="start" width="100%" ml="58px">
                    <StyledTypography
                        color="text.secondary"
                        variant="caption"
                        minWidth={120}
                        textAlign="left"
                        lineHeight="1.2em"
                    >
                        {getViewTitle()}
                    </StyledTypography>
                </Stack>
            )}
        </Stack>
    );
}

export default DetailedIceHockeyDesign;