import React, { useState } from "react";
import { Box, Stack, IconButton, useTheme, useMediaQuery, Typography } from "@mui/material";
import StyledTypography from "@/components/StyledComponents/EllipseTypography";
import { SoccerLiveDataTypeFragment } from "@/components/Common/GameLiveData/Fragments/SoccerLiveDataTypeFragment";
import formatSeconds from "@/utis/formatGameSeconds";
import SensorsIcon from "@mui/icons-material/Sensors";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useTranslations } from "next-intl";
import ScoreIndicatorLiveData from "../ScoreIndicatorLiveData";
import { formatPeriod, isGameFinished } from "../Utils";
import { LiveDataDesign } from "../DesignEnum";

type DetailedSoccerDesignProps = {
    gameLiveData: SoccerLiveDataTypeFragment;
    homeTeamName?: string;
    awayTeamName?: string;
};

const views = [
    "default", "corners", "yellowCards", "redCards",
    "fouls", "freeKicks", "goalKicks", "offsides"
] as const;

type ViewType = typeof views[number];

function DetailedSoccerDesign({
    gameLiveData,
    homeTeamName,
    awayTeamName
}: DetailedSoccerDesignProps) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const t = useTranslations("liveData");
    const [currentView, setCurrentView] = useState<ViewType>("default");

    const {
        homeTeamScoreByHalf = [0],
        awayTeamScoreByHalf = [0],
        homeTeamHasPossession = false,
        gameTimeSeconds = 0,
        additionalData = "",
        period = "",
        homeTeamCornersByHalf = [0],
        awayTeamCornersByHalf = [0],
        homeTeamYellowCardsByHalf = [0],
        awayTeamYellowCardsByHalf = [0],
        homeTeamRedCardsByHalf = [0],
        awayTeamRedCardsByHalf = [0],
        homeTeamFoulsByHalf = [0],
        awayTeamFoulsByHalf = [0],
        homeTeamFreeKicksByHalf = [0],
        awayTeamFreeKicksByHalf = [0],
        homeTeamGoalKicksByHalf = [0],
        awayTeamGoalKicksByHalf = [0],
        homeTeamOffsidesByHalf = [0],
        awayTeamOffsidesByHalf = [0],
        isAtTimeout
    } = gameLiveData;

    // Compute derived values
    const homeTeamScore = homeTeamScoreByHalf?.reduce((acc, curr) => acc + (curr ?? 0), 0);
    const awayTeamScore = awayTeamScoreByHalf?.reduce((acc, curr) => acc + (curr ?? 0), 0);
    const formattedGameTime = formatSeconds(gameTimeSeconds);
    const gameFinished = isGameFinished(period);
    const formattedPeriod = formatPeriod(period);

    const getTotal = (data: number[] | null) => (data ?? []).reduce((a, b) => a + b, 0);

    // Navigation between views
    const handleViewChange = (direction: "next" | "prev") => {
        const currentIndex = views.indexOf(currentView);
        const newIndex = direction === "next"
            ? (currentIndex + 1) % views.length
            : (currentIndex - 1 + views.length) % views.length;
        setCurrentView(views[newIndex]);
    };

    // Get view title based on current view
    const getViewTitle = () => {
        switch (currentView) {
            case "default": return t("scores_by_half");
            case "corners": return t("corners");
            case "yellowCards": return t("yellow_cards");
            case "redCards": return t("red_cards");
            case "fouls": return t("fouls");
            case "freeKicks": return t("free_kicks");
            case "goalKicks": return t("goal_kicks");
            case "offsides": return t("offsides");
            default: return "";
        }
    };

    // Get current view data
    const getViewData = () => {
        switch (currentView) {
            case "default":
                return {
                    awayData: awayTeamScoreByHalf,
                    homeData: homeTeamScoreByHalf,
                    awayScore: awayTeamScore,
                    homeScore: homeTeamScore,
                };
            case "corners":
                return {
                    awayData: awayTeamCornersByHalf,
                    homeData: homeTeamCornersByHalf,
                    awayScore: getTotal(awayTeamCornersByHalf),
                    homeScore: getTotal(homeTeamCornersByHalf),
                };
            case "yellowCards":
                return {
                    awayData: awayTeamYellowCardsByHalf,
                    homeData: homeTeamYellowCardsByHalf,
                    awayScore: getTotal(awayTeamYellowCardsByHalf),
                    homeScore: getTotal(homeTeamYellowCardsByHalf),
                };
            case "redCards":
                return {
                    awayData: awayTeamRedCardsByHalf,
                    homeData: homeTeamRedCardsByHalf,
                    awayScore: getTotal(awayTeamRedCardsByHalf),
                    homeScore: getTotal(homeTeamRedCardsByHalf),
                };
            case "fouls":
                return {
                    awayData: awayTeamFoulsByHalf,
                    homeData: homeTeamFoulsByHalf,
                    awayScore: getTotal(awayTeamFoulsByHalf),
                    homeScore: getTotal(homeTeamFoulsByHalf),
                };
            case "freeKicks":
                return {
                    awayData: awayTeamFreeKicksByHalf,
                    homeData: homeTeamFreeKicksByHalf,
                    awayScore: getTotal(awayTeamFreeKicksByHalf),
                    homeScore: getTotal(homeTeamFreeKicksByHalf),
                };
            case "goalKicks":
                return {
                    awayData: awayTeamGoalKicksByHalf,
                    homeData: homeTeamGoalKicksByHalf,
                    awayScore: getTotal(awayTeamGoalKicksByHalf),
                    homeScore: getTotal(homeTeamGoalKicksByHalf),
                };
            case "offsides":
                return {
                    awayData: awayTeamOffsidesByHalf,
                    homeData: homeTeamOffsidesByHalf,
                    awayScore: getTotal(awayTeamOffsidesByHalf),
                    homeScore: getTotal(homeTeamOffsidesByHalf),
                };
            default:
                return {
                    awayData: [],
                    homeData: [],
                    awayScore: 0,
                    homeScore: 0,
                };
        }
    };

    const { awayData, homeData, awayScore, homeScore } = getViewData();

    // Define reusable UI styles
    const teamColumnWidth = isMobile ? 52 : 130;
    const containerWidth = isMobile ? 170 : 260;
    const additionalDataWidth = isMobile ? 135 : 186;
    const halfCellWidth = 16;

    // Render half headers with data
    const renderHalfData = (data: number[] | null, keyPrefix: string) => (
        <Stack direction="row" spacing={1} justifyContent="flex-end">
            {Array(2).fill(undefined).map((_, index) => (
                <StyledTypography
                    color="text.primary"
                    variant="body2"
                    key={`${keyPrefix}-${index}`}
                    sx={{ width: halfCellWidth, textAlign: 'center' }}
                >
                    {data && data[index] !== undefined ? data[index] : "-"}
                </StyledTypography>
            ))}
        </Stack>
    );

    return (
        <Stack pb={isMobile ? 1 : 2} px={isMobile ? 1 : 2} gap={1}>
            {/* Header section */}
            <Stack direction="row" alignItems="center" justifyContent="space-between">
                {
                    gameFinished ? (
                        <Stack alignItems="center" direction="row" gap={0.5} >
                            <Typography color="text.secondary" variant="label" textAlign="left">
                                FINAL
                            </Typography>
                        </Stack>
                    ) : (
                        <Stack alignItems="center" direction="row" gap={0.5} >
                            <SensorsIcon fontSize="small" color={isAtTimeout || gameFinished ? "disabled" : "error"} />
                            <StyledTypography variant="body2" color="text.secondary">
                                Live Data
                            </StyledTypography>
                        </Stack>
                    )
                }

                <Stack direction="row" alignItems="center" pl={2}>
                    {
                        !gameFinished && (
                            <StyledTypography variant="caption" color="text.primary" fontWeight="bold">
                                {formattedPeriod} {formattedGameTime}
                            </StyledTypography>
                        )
                    }
                </Stack>


                {/* View controls */}
                <Stack direction="row" alignItems="center">
                    {!isMobile && (
                        <StyledTypography
                            variant="caption"
                            color="text.secondary"
                            sx={{ width: 120, textAlign: "end" }}
                        >
                            {getViewTitle()}
                        </StyledTypography>
                    )}
                    <IconButton onClick={() => handleViewChange("prev")} sx={{ py: 0 }}>
                        <KeyboardArrowLeftIcon />
                    </IconButton>
                    <IconButton onClick={() => handleViewChange("next")} sx={{ py: 0 }}>
                        <KeyboardArrowRightIcon />
                    </IconButton>
                </Stack>
            </Stack>

            {/* Main content */}
            <Stack direction="row" alignItems="start">
                <Stack direction="row" alignItems="center" width={containerWidth}>
                    <Stack
                        direction="column"
                        spacing={0}
                        sx={{
                            borderRight: `0.5px solid ${theme.palette.border.primary}`,
                            pr: 1,
                            flexShrink: 0,
                        }}
                    >
                        {/* Away team row */}
                        <Stack direction="row" spacing={1} alignItems="center">
                            <Box width={teamColumnWidth} flexShrink={0}>
                                <ScoreIndicatorLiveData
                                    teamName={awayTeamName}
                                    isServing={!homeTeamHasPossession}
                                    design={LiveDataDesign.DETAILED}
                                />
                            </Box>
                            {renderHalfData(awayData, 'away-data')}
                        </Stack>

                        {/* Half headers */}
                        <Stack direction="row" spacing={1} alignItems="center">
                            <Box width={teamColumnWidth} flexShrink={0} lineHeight="12px" />
                            <Stack direction="row" spacing={1} justifyContent="flex-end">
                                {["1", "2"].map((header, index) => (
                                    <StyledTypography
                                        color="text.secondary"
                                        variant="label"
                                        key={`header-${index}`}
                                        sx={{ width: halfCellWidth, textAlign: 'center' }}
                                    >
                                        {header}
                                    </StyledTypography>
                                ))}
                            </Stack>
                        </Stack>

                        {/* Home team row */}
                        <Stack direction="row" spacing={1} alignItems="center">
                            <Box width={teamColumnWidth} flexShrink={0}>
                                <ScoreIndicatorLiveData
                                    teamName={homeTeamName}
                                    isServing={homeTeamHasPossession}
                                    design={LiveDataDesign.DETAILED}
                                />
                            </Box>
                            {renderHalfData(homeData, 'home-data')}
                        </Stack>
                    </Stack>

                    {/* Total scores */}
                    <Stack direction="column" gap={1.5} alignItems="start">
                        <ScoreIndicatorLiveData
                            score={awayScore?.toString()}
                            design={LiveDataDesign.DETAILED}
                            view={currentView}
                        />
                        <ScoreIndicatorLiveData
                            score={homeScore?.toString()}
                            design={LiveDataDesign.DETAILED}
                            view={currentView}
                        />
                    </Stack>
                </Stack>

                {/* Additional data */}
                <Box width={additionalDataWidth}>
                    <StyledTypography
                        lineClamp={4}
                        mt={0.5}
                        color="text.secondary"
                        variant="body2"
                        sx={{
                            lineHeight: isMobile ? "1em" : "1.2em",
                            display: "-webkit-box",
                            WebkitBoxOrient: "vertical",
                            whiteSpace: "normal",
                            opacity: 0.5,
                        }}
                    >
                        {additionalData}
                    </StyledTypography>
                </Box>
            </Stack>

            {/* Mobile view title */}
            {isMobile && (
                <Stack direction="row" justifyContent="start" width="100%" ml="58px">
                    <StyledTypography
                        color="text.secondary"
                        variant="caption"
                        minWidth={120}
                        textAlign="left"
                        lineHeight="1.2em"
                    >
                        {getViewTitle()}
                    </StyledTypography>
                </Stack>
            )}
        </Stack>
    );
}

export default DetailedSoccerDesign;