import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import SensorsIcon from "@mui/icons-material/Sensors";
import { IceHockeyLiveDataTypeFragment } from "@/components/Common/GameLiveData/Fragments/IceHockeyLiveDataTypeFragment";
import formatSeconds from "@/utis/formatGameSeconds";
import StyledTypography from "@/components/StyledComponents/EllipseTypography";
import ScoreIndicatorLiveData from "../ScoreIndicatorLiveData";
import ElapsedTime from "../ElapsedTime";
import { LiveDataDesign } from "../DesignEnum";
import { formatPeriod, isGameFinished } from "../Utils";


type CompactIceHockeyDesignProps = {
    gameLiveData: IceHockeyLiveDataTypeFragment;
    startTime?: number;
};


function CompactIceHockeyDesign({ gameLiveData, startTime }: CompactIceHockeyDesignProps) {
    const {
        homeTeamGoalsByPeriod = [0],
        awayTeamGoalsByPeriod = [0],
        period = "",
        gameTimeSeconds = 0,
        homeTeamHasPossession = false,
        isAtTimeout
    } = gameLiveData;

    const homeTeamScoreNum = homeTeamGoalsByPeriod?.reduce((acc, curr) => acc + curr, 0) || 0;
    const awayTeamScoreNum = awayTeamGoalsByPeriod?.reduce((acc, curr) => acc + curr, 0) || 0;

    const homeTeamScore = homeTeamScoreNum.toString();
    const awayTeamScore = awayTeamScoreNum.toString();

    const formattedGameTime = formatSeconds(gameTimeSeconds);
    const gameFinished = isGameFinished(period);
    const formattedPeriod = formatPeriod(period);

    return (
        <>
            {
                gameFinished ? (
                    <Box position="absolute" top={0} left={0} sx={{ transform: "translate(30%, -20%)" }}>
                        <Typography color="text.secondary" variant="smallText" textAlign="left">
                            FINAL
                        </Typography>
                    </Box>
                ) : (
                    <Box position="absolute" top={0} left={0} sx={{ transform: "translate(5%, 0%)" }}>
                        <SensorsIcon color={isAtTimeout ? "disabled" : "error"} sx={{
                            height: "16px",
                        }} />
                    </Box>
                )
            }

            <Stack direction="row" alignItems="center" gap={1}>
                <Stack direction="column" alignItems="end" gap={1}>
                    <ScoreIndicatorLiveData
                        score={awayTeamScore}
                        isServing={!homeTeamHasPossession}
                        design={LiveDataDesign.COMPACT}
                        isFinished={gameFinished}
                    // highlight={gameFinished && awayTeamScoreNum > homeTeamScoreNum}
                    />
                    <ScoreIndicatorLiveData
                        score={homeTeamScore}
                        isServing={homeTeamHasPossession}
                        design={LiveDataDesign.COMPACT}
                        isFinished={gameFinished}
                    // highlight={gameFinished && homeTeamScoreNum > awayTeamScoreNum}
                    />
                </Stack>

                <Stack direction="column" alignItems="start" justifyContent="flex-end" mt="14px" gap={0.5}>
                    <StyledTypography variant="caption"
                        color={gameFinished ? "text.secondary" : "text.primary"}
                        fontSize={gameFinished ? 10 : 14}
                        lineHeight={gameFinished ? "8px" : "14px"}
                    >{formattedPeriod}</StyledTypography>
                    <Box>
                        {!gameFinished && <StyledTypography variant="caption" fontSize={14} lineHeight="8px">{formattedGameTime}</StyledTypography>}
                        <ElapsedTime startTime={startTime} isgameLive />
                    </Box>
                </Stack>
            </Stack>
        </>
    );
};

export default CompactIceHockeyDesign;