import React from "react";
import useElapsedTime from "@/hooks/useElapsedTime";
import { useTranslations } from "next-intl";
import StyledTypography from "@/components/StyledComponents/EllipseTypography";
import { formatCustomElapsedTime } from "./Utils";

type ElapsedTimeDisplayProps = {
    startTime?: number;
    isgameLive: boolean;
};

function ElapsedTime({ startTime, isgameLive }: ElapsedTimeDisplayProps) {
    const { elapsedTime, isFuture } = useElapsedTime(startTime);
    const t = useTranslations("odds");

    return (
        <StyledTypography
            variant="supScript"
            color={isgameLive ? "text.secondary" : "text.primary"}
            lineClamp={2}
            sx={{
                maxWidth: "60px",
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
            }}
            lineHeight="10px"
        >
            {isFuture
                ? `${t("in")} ${formatCustomElapsedTime(elapsedTime)}`
                : formatCustomElapsedTime(elapsedTime)}
        </StyledTypography>
    );
}

export default React.memo(ElapsedTime);