import { useEffect, useMemo, useState } from "react";
import { fromUnixTime, formatDistanceToNowStrict } from "date-fns";

function useElapsedTime(startTime?: number, intervalTime?: number) {
  const [currentTime, setCurrentTime] = useState(new Date());
  const [elapsedTimeStr, setElapsedTimeStr] = useState("");

  const startDate = useMemo(() => (startTime ? fromUnixTime(startTime) : null), [startTime]);

  const isFuture = useMemo(() => (startDate ? startDate > currentTime : false), [startDate, currentTime]);

  useEffect((): (() => void) => {
    if (!startDate) {
      return () => undefined;
    }

    const calculateElapsedTime = () => {
      const now = new Date();
      setCurrentTime(now);
      setElapsedTimeStr(formatDistanceToNowStrict(startDate, { addSuffix: !isFuture }));
    };

    calculateElapsedTime();
    // Why this need to be 1 sec. I think updating it on 5 sec would also do
    const interval = setInterval(calculateElapsedTime, intervalTime || 5000);

    return () => clearInterval(interval);
  }, [startDate, isFuture, intervalTime]);

  return {
    elapsedTime: elapsedTimeStr,
    isFuture,
  };
}

export default useElapsedTime;
