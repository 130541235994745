import React from "react";
import { Box } from "@mui/material";
import { IceHockeyLiveDataTypeFragment } from "@/components/Common/GameLiveData/Fragments/IceHockeyLiveDataTypeFragment";
import { LiveDataDesign } from "../DesignEnum";
import DefaultIceHockeyDesign from "./DefaultIceHockeyDesign";
import CompactIceHockeyDesign from "./CompactIceHockeyDesign";
import DetailedIceHockeyDesign from "./DetailedIceHockeyDesign";
import BasicIceHockeyDesign from "./BasicIceHockeyDesign";

type IceHockeyLiveDataProps = {
    gameLiveData: IceHockeyLiveDataTypeFragment | null;
    startTime?: number;
    design: LiveDataDesign;
    showAdditionalData?: boolean;
    homeTeamName?: string;
    awayTeamName?: string;
};


function IceHockeyLiveData({ gameLiveData, startTime, design, showAdditionalData, homeTeamName, awayTeamName }: IceHockeyLiveDataProps) {

    if (!gameLiveData) {
        return (
            <Box sx={{ textAlign: "center", p: 2 }}>
                <p>Game data is unavailable.</p>
            </Box>
        );
    }

    const designMap: Record<LiveDataDesign, React.ElementType> = {
        [LiveDataDesign.DEFAULT]: DefaultIceHockeyDesign,
        [LiveDataDesign.COMPACT]: CompactIceHockeyDesign,
        [LiveDataDesign.DETAILED]: DetailedIceHockeyDesign,
        [LiveDataDesign.BASIC]: BasicIceHockeyDesign,
    };

    const DesignComponent = designMap[design] || DefaultIceHockeyDesign;

    return <DesignComponent gameLiveData={gameLiveData} startTime={startTime} showAdditionalData={showAdditionalData} homeTeamName={homeTeamName} awayTeamName={awayTeamName} />;
}

export default IceHockeyLiveData;
