import React from "react";
import { Box } from "@mui/material";
import { FootballLiveDataTypeFragment } from "@/components/Common/GameLiveData/Fragments/FootballLiveDataTypeFragment";
import { LiveDataDesign } from "../DesignEnum";
import DefaultFootballDesign from "./DefaultFootballDesign";
import CompactFootballDesign from "./CompactFootballDesign";
import DetailedFootballDesign from "./DetailedFootballDesign";
import BasicFootBallDesign from "./BasicFootBallDesign";

type FootballLiveDataProps = {
    gameLiveData: FootballLiveDataTypeFragment | null;
    startTime?: number;
    design: LiveDataDesign;
    homeTeamName?: string;
    awayTeamName?: string;
    showAdditionalData?: boolean;
    isMobile?: boolean;
    homeTeamAbbr?: string;
    awayTeamAbbr?: string;
};

function FootballLiveData({ gameLiveData, startTime, design, homeTeamAbbr, awayTeamAbbr, homeTeamName, awayTeamName, showAdditionalData, isMobile }: FootballLiveDataProps) {

    if (!gameLiveData) {
        return (
            <Box sx={{ textAlign: "center", p: 2 }}>
                <p>Game data is unavailable.</p>
            </Box>
        );
    }

    const designMap: Record<LiveDataDesign, React.ElementType> = {
        [LiveDataDesign.DEFAULT]: DefaultFootballDesign,
        [LiveDataDesign.COMPACT]: CompactFootballDesign,
        [LiveDataDesign.DETAILED]: DetailedFootballDesign,
        [LiveDataDesign.BASIC]: BasicFootBallDesign
    };

    const DesignComponent = designMap[design] || DefaultFootballDesign;

    return <DesignComponent gameLiveData={gameLiveData} startTime={startTime} homeTeamAbbr={homeTeamAbbr} awayTeamAbbr={awayTeamAbbr} homeTeamName={homeTeamName} awayTeamName={awayTeamName} showAdditionalData={showAdditionalData} isMobile={isMobile} />;
}

export default FootballLiveData;
