import { League } from "@/components/Common/Enums/LeagueEnum";
import { SportConstantType } from "@/store/constants";

export default function formatLeagueName(league: League, sports?: SportConstantType[]): string {
    if (!sports) return league;

    const leagueObj = sports
        .flatMap(sport => sport.leagues || [])
        .find(l => l.leagueEnum === league);

    return leagueObj ? leagueObj.displayName : league;
}