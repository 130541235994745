import React from "react";
import { Box } from "@mui/material";
import { BasketballLiveDataTypeFragment } from "@/components/Common/GameLiveData/Fragments/BasketballLiveDataTypeFragment";
import { LiveDataDesign } from "../DesignEnum";
import DefaultBasketballDesign from "./DefaultBasketballDesign";
import CompactBasketballDesign from "./CompactBasketballDesign";
import DetailedBasketballDesign from "./DetailedBasketballDesign";
import BasicBasketBallDesign from "./BasicBasketBallDesign";

type BasketballLiveDataProps = {
    gameLiveData: BasketballLiveDataTypeFragment | null;
    startTime?: number;
    design: LiveDataDesign;
    showAdditionalData?: boolean;
    awayTeamName?: string;
    homeTeamName?: string;
};

function BasketballLiveData({ gameLiveData, startTime, design, showAdditionalData, homeTeamName, awayTeamName }: BasketballLiveDataProps) {

    if (!gameLiveData) {
        return (
            <Box sx={{ textAlign: "center", p: 2 }}>
                <p>Game data is unavailable.</p>
            </Box>
        );
    }

    const designMap: Record<LiveDataDesign, React.ElementType> = {
        [LiveDataDesign.DEFAULT]: DefaultBasketballDesign,
        [LiveDataDesign.COMPACT]: CompactBasketballDesign,
        [LiveDataDesign.DETAILED]: DetailedBasketballDesign,
        [LiveDataDesign.BASIC]: BasicBasketBallDesign
    };

    const DesignComponent = designMap[design] || DefaultBasketballDesign;

    return <DesignComponent gameLiveData={gameLiveData} startTime={startTime} showAdditionalData={showAdditionalData} awayTeamAbbr={awayTeamName} homeTeamAbbr={homeTeamName} />;
}

export default BasketballLiveData;
