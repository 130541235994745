import React from "react";
import { Box, Stack, useTheme } from "@mui/material";
import StyledTypography from "@/components/StyledComponents/EllipseTypography";
import { BaseballLiveDataTypeFragment } from "@/components/Common/GameLiveData/Fragments/BaseballLiveDataTypeFragment";
import ScoreIndicatorLiveData from "../ScoreIndicatorLiveData";
import { LiveDataDesign } from "../DesignEnum";

type DefaultBaseballDesignProps = {
    gameLiveData: BaseballLiveDataTypeFragment;
    showAdditionalData?: boolean;
};

export function RenderBases(bases: boolean[]) {
    const theme = useTheme();
    return (
        <Box sx={{ position: "relative", left: "50%", transform: "translate(-50%)" }}>
            {/* First rhomboid */}
            <Box
                sx={{
                    position: "absolute",
                    width: "10px",
                    height: "10px",
                    background: bases[2] ? theme.palette.secondary.main : theme.palette.divider,
                    transform: "rotate(-45deg)",
                    top: "-15px",
                    left: "-15px", // Adjust positioning
                }}
            />
            {/* Second rhomboid */}
            <Box
                sx={{
                    position: "absolute",
                    width: "10px",
                    height: "10px",
                    background: bases[1] ? theme.palette.secondary.main : theme.palette.divider,
                    transform: "rotate(-45deg)",
                    top: "-24px",
                    left: "-5px", // Centered horizontally
                }}
            />
            {/* Third rhomboid */}
            <Box
                sx={{
                    position: "absolute",
                    width: "10px",
                    height: "10px",
                    background: bases[0] ? theme.palette.secondary.main : theme.palette.divider,
                    transform: "rotate(-45deg)",
                    top: "-15px", // Positioned below the first two
                    left: "5px", // Align with first rhomboid
                }}
            />
        </Box>
    );
}

export function RenderOuts(outs: number[]) {
    // Get the number of outs for the most recent inning
    const latestOuts = outs.length > 0 ? outs[outs.length - 1] : 0;

    // Ensure that we only display up to three outs
    const outsToRender = Math.min(latestOuts, 3);
    const theme = useTheme();
    return (
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            {Array.from({ length: 3 }, (_, index) => (
                <Box
                    key={index}
                    sx={{
                        width: "8px",
                        height: "8px",
                        borderRadius: "50%",
                        backgroundColor: index < outsToRender ? theme.palette.secondary.main : theme.palette.divider,
                        margin: "0 2px",
                    }}
                />
            ))}
        </Box>
    );
}

export function RenderInningIndicator(inning: number, isHomeTeamBatting: boolean) {
    const theme = useTheme();

    if (!inning) {
        return null
    }
    return (
        <Stack direction="column" alignItems="center">
            {/* Top Triangle */}
            <Box
                sx={{
                    width: 0,
                    height: 0,
                    borderLeft: "4px solid transparent",
                    borderRight: "4px solid transparent",
                    borderBottom: "8px solid",
                    borderBottomColor: isHomeTeamBatting ? theme.palette.divider : theme.palette.secondary.main,
                }}
            />
            <StyledTypography variant="body2" color="text.primary">
                {inning}
            </StyledTypography>
            {/* Bottom Triangle */}
            <Box
                sx={{
                    width: 0,
                    height: 0,
                    borderLeft: "4px solid transparent",
                    borderRight: "4px solid transparent",
                    borderTop: "8px solid",
                    borderTopColor: isHomeTeamBatting ? theme.palette.secondary.main : theme.palette.divider,
                }}
            />
        </Stack>
    );
}


function DefaultBaseballDesign({ gameLiveData, showAdditionalData }: DefaultBaseballDesignProps) {
    const {
        awayTeamScoreByInning = [0],
        homeTeamScoreByInning = [0],
        isHomeTeamBatting = false,
        period = "00",
        currentStrikes = 0,
        currentBalls = 0,
        awayTeamCurrentBases = [false, false, false],
        homeTeamCurrentBases = [false, false, false],
        awayTeamOutsByInning = [0],
        homeTeamOutsByInning = [0],
        additionalData,
    } = gameLiveData;

    const homeTeamScore = homeTeamScoreByInning?.reduce((acc, curr) => acc + (curr ?? 0), 0);
    const awayTeamScore = awayTeamScoreByInning?.reduce((acc, curr) => acc + (curr ?? 0), 0);

    const currentOuts = (isHomeTeamBatting ? awayTeamOutsByInning : homeTeamOutsByInning) || [];

    return (
        <Box>
            <Stack direction="row" spacing={1} alignItems="start">
                <Stack direction="row" spacing={1} alignItems="center" sx={{ width: "fit-content", minWidth: "120px" }}>
                    <Stack direction="column" spacing={0} alignItems="end">
                        <ScoreIndicatorLiveData score={homeTeamScore?.toString()} isServing={false} design={LiveDataDesign.COMPACT} />
                        <ScoreIndicatorLiveData score={awayTeamScore?.toString()} isServing={false} design={LiveDataDesign.COMPACT} />
                    </Stack>

                    <Stack direction="column" alignItems="center">
                        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }} pt={2.5}>
                            {RenderBases((isHomeTeamBatting ? homeTeamCurrentBases : awayTeamCurrentBases) || [false, false, false])}
                        </Box>

                        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>{RenderOuts(currentOuts)}</Box>
                    </Stack>

                    <Stack direction="row" alignItems="center">
                        <StyledTypography mt={0.5} variant="body2" color="text.primary">
                            {currentBalls}
                        </StyledTypography>
                        <StyledTypography mt={0.5} variant="body2" color="text.secondary">
                            -
                        </StyledTypography>
                        <StyledTypography mt={0.5} variant="body2" color="text.primary">
                            {currentStrikes}
                        </StyledTypography>
                    </Stack>

                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <StyledTypography variant="body2" color="text.secondary">
                            {RenderInningIndicator(parseInt(period, 10), isHomeTeamBatting)}
                        </StyledTypography>
                    </Box>
                </Stack>
                {
                    showAdditionalData && (
                        <Box>
                            <StyledTypography
                                lineClamp={3}
                                mt={0.5}
                                color="text.secondary"
                                variant="caption"
                                sx={{
                                    lineHeight: "1em",
                                    display: "-webkit-box",
                                    WebkitBoxOrient: "vertical",
                                    whiteSpace: "normal",
                                }}
                            >
                                {additionalData}
                            </StyledTypography>
                        </Box>
                    )}
            </Stack>
        </Box>
    );
};

export default DefaultBaseballDesign;
