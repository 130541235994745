import React from "react";
import { Box, Divider, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import SensorsIcon from "@mui/icons-material/Sensors";
import { TennisLiveDataTypeFragment } from "@/components/Common/GameLiveData/Fragments/TennisLiveDataTypeFragment";
import StyledTypography from "@/components/StyledComponents/EllipseTypography";
import ScoreIndicatorLiveData from "../ScoreIndicatorLiveData";
import { LiveDataDesign } from "../DesignEnum";
import ElapsedTime from "../ElapsedTime";

type CompactTennisDesignProps = {
    gameLiveData: TennisLiveDataTypeFragment;
    startTime: number;
};

function CompactTennisDesign({ gameLiveData, startTime }: CompactTennisDesignProps) {
    const {
        player1GamesBySet = [],
        player2GamesBySet = [],
        isPlayer1Serving = false,
        player1Points = 0,
        player2Points = 0,
        period,
        isAtTimeout
    } = gameLiveData;

    const currentSetIndex = Math.max((player1GamesBySet ?? []).length - 1, 0);

    const calculateSetsWon = (playerGames: number[], opponentGames: number[]) =>
        playerGames.reduce((setsWon, games, index) =>
            games >= 6 && games > opponentGames[index] && (games - opponentGames[index] >= 2 || games >= 7) ? setsWon + 1 : setsWon, 0);

    const player1SetsWon = calculateSetsWon(player1GamesBySet ?? [], player2GamesBySet ?? []);
    const player2SetsWon = calculateSetsWon(player2GamesBySet ?? [], player1GamesBySet ?? []);
    const isGameFinished = period === 'Finished' || period === 'Suspended';
    const calculatePoints = () => {
        if (isGameFinished && player1GamesBySet && player2GamesBySet) {
            const setsCount = { player1: 0, player2: 0 };

            for (let i = 0; i < player1GamesBySet.length; i += 1) {
                const p1Games = player1GamesBySet[i];
                const p2Games = player2GamesBySet[i];

                // Standard set win (6 games with 2-game lead)
                if (p1Games >= 6 || p2Games >= 6) {
                    if (p1Games >= 6 && p1Games - p2Games >= 2) {
                        setsCount.player1 += 1;
                    } else if (p2Games >= 6 && p2Games - p1Games >= 2) {
                        setsCount.player2 += 1;
                    }
                }

                // Tiebreaker rule: If 6-6, the set goes to 7-6
                if (p1Games === 7 && p2Games === 6) {
                    setsCount.player1 += 1; // Player 1 wins 7-6
                } else if (p2Games === 7 && p1Games === 6) {
                    setsCount.player2 += 1; // Player 2 wins 7-6
                }
            }

            return setsCount;
        }

        return {
            player1: player1Points,
            player2: player2Points
        };
    };


    const points = calculatePoints();
    const player1Score = points.player1?.toString();
    const player2Score = points.player2?.toString();


    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    // const p1ScoreNum = parseInt(player1Score, 10) || 0;
    // const p2ScoreNum = parseInt(player2Score, 10) || 0;


    return isGameFinished ? (
        <>
            <Box position="absolute" top={0} left={0} sx={{ transform: "translate(30%, -20%)" }}>
                <Typography color="text.secondary" variant="smallText" textAlign="left">
                    FINAL
                </Typography>
            </Box>
            <Box mt={0.5}>
                <Stack direction="row" justifyContent="center" alignItems="center" >
                    <Stack direction="row" gap={1}>
                        {player1GamesBySet?.map((p1Score, index) => {
                            const p2Score = player2GamesBySet?.[index] ?? 0;
                            return (
                                <Stack key={`set-${index}`} direction="column" alignItems="center">
                                    <StyledTypography
                                        variant="body2"
                                        color="text.primary"
                                        lineHeight="12px"
                                        sx={{ fontWeight: p1Score > p2Score ? "bold" : "normal" }}
                                    >
                                        {p1Score}
                                    </StyledTypography>
                                    <StyledTypography variant="caption" color="text.secondary" sx={{
                                        opacity: 0.5
                                    }}>
                                        {index + 1}
                                    </StyledTypography>
                                    <StyledTypography
                                        variant="body2"
                                        color="text.primary"
                                        lineHeight="12px"
                                        sx={{ fontWeight: p2Score > p1Score ? "bold" : "normal" }}
                                    >
                                        {p2Score}
                                    </StyledTypography>
                                </Stack>
                            );
                        })}
                    </Stack>

                    <Divider orientation="vertical" flexItem sx={{
                        ml: 1,
                        borderColor: theme.palette.text.primary
                    }} />

                    <Stack direction="column" gap={1.5}>
                        <ScoreIndicatorLiveData
                            score={player1Score}
                            isServing={isPlayer1Serving}
                            design={LiveDataDesign.COMPACT}
                            isFinished
                        // highlight={p1ScoreNum > p2ScoreNum}
                        />
                        <ScoreIndicatorLiveData
                            score={player2Score}
                            isServing={!isPlayer1Serving}
                            design={LiveDataDesign.COMPACT}
                            isFinished
                        // highlight={p2ScoreNum > p1ScoreNum}
                        />
                    </Stack>
                </Stack>
            </Box>
        </>
    ) : (
        <>
            <Box position="absolute" top={0} left={0} sx={{ transform: "translate(5%, 0%)" }}>
                <SensorsIcon
                    color={period === 'Finished' || isAtTimeout ? 'disabled' : 'error'}
                    sx={{ height: "16px" }}
                />
            </Box>
            <Box>
                <Stack direction="row" gap={isMobile ? 1 : 1.5} alignItems="center">
                    <Stack direction="column" gap={1.3} alignItems="center" minWidth={20} width={20}>
                        <ScoreIndicatorLiveData
                            score={player1Score}
                            isServing={isPlayer1Serving}
                            design={LiveDataDesign.COMPACT}
                            isFinished={period === 'Finished'}
                        />
                        <ScoreIndicatorLiveData
                            score={player2Score}
                            isServing={!isPlayer1Serving}
                            design={LiveDataDesign.COMPACT}
                            isFinished={period === 'Finished'}
                        />
                    </Stack>

                    <Stack direction="column" alignItems="center">
                        <StyledTypography color="text.primary" variant="caption">
                            {(player1GamesBySet ?? [])[currentSetIndex] ?? 0}
                        </StyledTypography>
                        <StyledTypography variant="supScript" color="text.secondary">
                            Game
                        </StyledTypography>
                        <StyledTypography color="text.primary" variant="caption">
                            {(player2GamesBySet ?? [])[currentSetIndex] ?? 0}
                        </StyledTypography>
                    </Stack>

                    <Stack direction="column" alignItems="center">
                        <StyledTypography color="text.primary" variant="caption">
                            {player1SetsWon}
                        </StyledTypography>
                        <StyledTypography variant="supScript" color="text.secondary">
                            Set
                        </StyledTypography>
                        <StyledTypography color="text.primary" variant="caption">
                            {player2SetsWon}
                        </StyledTypography>
                    </Stack>
                </Stack>

                <Stack sx={{ alignItems: "center" }}>
                    <ElapsedTime startTime={startTime} isgameLive />
                </Stack>
            </Box>
        </>
    );
};

export default CompactTennisDesign;