import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import SensorsIcon from "@mui/icons-material/Sensors";
import { FootballLiveDataTypeFragment } from "@/components/Common/GameLiveData/Fragments/FootballLiveDataTypeFragment";
import formatSeconds from "@/utis/formatGameSeconds";
import StyledTypography from "@/components/StyledComponents/EllipseTypography";
import { determineYardsToGo, formatPeriod, isGameFinished, isInRedZone } from "../Utils";
import ScoreIndicatorLiveData from "../ScoreIndicatorLiveData";
import ElapsedTime from "../ElapsedTime";
import { LiveDataDesign } from "../DesignEnum";


type CompactFootballDesignProps = {
    gameLiveData: FootballLiveDataTypeFragment;
    startTime: number;
    awayTeamName?: string;
    homeTeamName?: string;
    homeTeamAbbr?: string;
    awayTeamAbbr?: string;
};

function CompactFootballDesign({ gameLiveData, homeTeamName, awayTeamName, homeTeamAbbr, awayTeamAbbr, startTime }: CompactFootballDesignProps) {
    const {
        awayTeamTotalPoints = [0],
        homeTeamTotalPoints = [0],
        homeTeamHasPossession = false,
        period = "00",
        gameTimeSeconds = 0,
        activeDown = "00",
        yardsToNextDown = "00",
        yardsDistance = "00",
        isAtTimeout
    } = gameLiveData;

    const homeTeamScore = homeTeamTotalPoints?.reduce((acc, curr) => acc + (curr ?? 0), 0);
    const awayTeamScore = awayTeamTotalPoints?.reduce((acc, curr) => acc + (curr ?? 0), 0);
    const isHomeTeamPossession = homeTeamHasPossession;
    const formattedYardsToGo = determineYardsToGo(yardsDistance?.toString() || "", homeTeamAbbr, awayTeamAbbr, isHomeTeamPossession);
    const redZoneStatus = isInRedZone(yardsDistance?.toString() || "");
    const gameFinished = isGameFinished(period);
    const formattedPeriod = formatPeriod(period);

    return (
        <>

            {
                gameFinished ? (
                    <Box position="absolute" top={0} left={0} sx={{ transform: "translate(30%, -20%)" }}>
                        <Typography color="text.secondary" variant="smallText" textAlign="left">
                            FINAL
                        </Typography>
                    </Box>
                ) : (
                    <Box position="absolute" top={0} left={0} sx={{ transform: "translate(5%, 0%)" }}>
                        <SensorsIcon color={isAtTimeout ? "disabled" : "error"} sx={{
                            height: "16px",
                        }} />
                    </Box>
                )
            }

            <Box>
                <Stack direction="row" spacing={0.5} alignItems="center" mt={0.5}>
                    <Stack direction="column" spacing={1} alignItems="end" sx={{ flexGrow: 1 }}>
                        <ScoreIndicatorLiveData score={awayTeamScore?.toString()} isServing={!isHomeTeamPossession} design={LiveDataDesign.COMPACT} isInRedZone={!isHomeTeamPossession && redZoneStatus} isFinished={gameFinished} />
                        <ScoreIndicatorLiveData score={homeTeamScore?.toString()} isServing={isHomeTeamPossession} design={LiveDataDesign.COMPACT} isInRedZone={isHomeTeamPossession && redZoneStatus} isFinished={gameFinished} />
                    </Stack>

                    <Stack direction="column" spacing={0} alignItems="start">
                        <StyledTypography variant="supScript" color="text.primary">
                            {formattedPeriod}
                        </StyledTypography>
                        <StyledTypography variant="supScript" color="text.primary" mb={0.5}>
                            {!gameFinished && formatSeconds(gameTimeSeconds)}
                        </StyledTypography>
                        <StyledTypography variant="supScript" color="text.primary">
                            {activeDown} & {yardsToNextDown}
                        </StyledTypography>
                        <StyledTypography variant="supScript" color="text.primary">
                            {formattedYardsToGo}
                        </StyledTypography>
                    </Stack>
                </Stack>

                <Stack sx={{ alignItems: "center" }}>
                    <ElapsedTime startTime={startTime} isgameLive />
                </Stack>
            </Box>
        </>
    );
};

export default CompactFootballDesign;
