import React, { useState } from "react";
import { Box, Stack, IconButton, useTheme, useMediaQuery, Typography, } from "@mui/material";
import StyledTypography from "@/components/StyledComponents/EllipseTypography";
import { BaseballLiveDataTypeFragment } from "@/components/Common/GameLiveData/Fragments/BaseballLiveDataTypeFragment";
import SensorsIcon from "@mui/icons-material/Sensors";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useTranslations } from "next-intl";
import { formatPeriod, isGameFinished } from "../Utils";
import ScoreIndicatorLiveData from "../ScoreIndicatorLiveData";
import { LiveDataDesign } from "../DesignEnum";
import { RenderBases, RenderInningIndicator, RenderOuts } from "./DefaultBaseballDesign";

type DefaultBaseballDesignProps = {
    gameLiveData: BaseballLiveDataTypeFragment;
    homeTeamName?: string;
    awayTeamName?: string;
};


const views = ["default", "score", "homeRun", "grandSlam", "outs", "additional"] as const;
type ViewType = typeof views[number];

type RenderScoreByInningProps = {
    dataType: ViewType;
    homeTeamData: number[];
    awayTeamData: number[];
};

function RenderScoreByInning({ dataType, homeTeamData, awayTeamData }: RenderScoreByInningProps) {
    const scoreCellWidth = 13;
    const theme = useTheme();

    return (
        <Stack direction="column">
            <Stack direction="row" gap={0.5}>
                {Array(9).fill(undefined).map((_, index) => (
                    <StyledTypography key={`home-${dataType}-${index}`} variant="body2" color="text.primary" sx={{ width: `${scoreCellWidth}px`, textAlign: "center" }}>
                        {homeTeamData[index] !== undefined && homeTeamData[index] !== null ? homeTeamData[index] : "-"}
                    </StyledTypography>
                ))}
                <Box sx={{ borderLeft: `1px solid ${theme.palette.text.primary}`, mx: 1 }} />
                <StyledTypography variant="body2" color="text.primary">
                    {homeTeamData.reduce((acc, curr) => acc + (curr ?? 0), 0)}
                </StyledTypography>
            </Stack>
            <Stack direction="row" gap={0.5} alignItems="flex-start">
                {Array.from({ length: 9 }, (_, index) => (
                    <StyledTypography key={`header-${index}`} variant="label" color="text.secondary" lineHeight="10px" sx={{ width: `${scoreCellWidth}px`, textAlign: "center" }}>
                        {index + 1}
                    </StyledTypography>
                ))}
                <Box sx={{ borderLeft: `1px solid ${theme.palette.text.primary}`, mx: 1 }} />
                <StyledTypography variant="label" lineHeight="10px" color="text.secondary">
                    {dataType === "outs" ? "O" : "R"}
                </StyledTypography>
            </Stack>
            <Stack direction="row" gap={0.5}>
                {Array(9).fill(undefined).map((_, index) => (
                    <StyledTypography key={`away-${dataType}-${index}`} variant="body2" color="text.primary" sx={{ width: `${scoreCellWidth}px`, textAlign: "center" }}>
                        {awayTeamData[index] !== undefined && awayTeamData[index] !== null ? awayTeamData[index] : "-"}
                    </StyledTypography>
                ))}
                <Box sx={{ borderLeft: `1px solid ${theme.palette.text.primary}`, mx: 1 }} />
                <StyledTypography variant="body2" color="text.primary">
                    {awayTeamData.reduce((acc, curr) => acc + (curr ?? 0), 0)}
                </StyledTypography>
            </Stack>
        </Stack>
    );
};




function DetailedBaseballDesign({ gameLiveData, homeTeamName, awayTeamName }: DefaultBaseballDesignProps) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const [currentView, setCurrentView] = useState<ViewType>(isMobile ? "additional" : "score");

    const {
        awayTeamScoreByInning = [0],
        homeTeamScoreByInning = [0],
        isHomeTeamBatting = false,
        period = "00",
        currentStrikes = 0,
        currentBalls = 0,
        awayTeamCurrentBases = [false, false, false],
        homeTeamCurrentBases = [false, false, false],
        awayTeamOutsByInning = [0],
        homeTeamOutsByInning = [0],
        awayTeamHomeRunByInning = [0],
        homeTeamHomeRunByInning = [0],
        awayTeamGrandSlamsByInning = [0],
        homeTeamGrandSlamsByInning = [0],
        additionalData,
        isAtTimeout
    } = gameLiveData;

    const currentOuts = (isHomeTeamBatting ? awayTeamOutsByInning : homeTeamOutsByInning) || [];

    const gameFinished = isGameFinished(period);
    const formattedPeriod = formatPeriod(period);


    const t = useTranslations("liveData");
    const headers = {
        score: t("score_by_inning"),
        homeRun: t("homeRun_by_inning"),
        grandSlam: t("grandSlam_by_inning"),
        outs: t("outs_by_inning"),
    };
    const headerText = currentView !== "default" && currentView !== "additional" ? headers[currentView as keyof typeof headers] : "";



    const handleViewChange = (direction: "next" | "prev") => {
        const currentIndex = views.indexOf(currentView);
        const newIndex = direction === "next" ? (currentIndex + 1) % views.length : (currentIndex - 1 + views.length) % views.length;
        setCurrentView(views[newIndex]);
    };



    // Add renderContent function before the return statement in DetailedBaseballDesign
    const renderContent = () => {
        if (currentView === "additional" && isMobile) {
            return (
                <Box width={120}>
                    <StyledTypography
                        lineClamp={4}
                        mt={0.5}
                        color="text.secondary"
                        variant="body2"
                        sx={{
                            lineHeight: "1em",
                            display: "-webkit-box",
                            WebkitBoxOrient: "vertical",
                            whiteSpace: "normal",
                            opacity: 0.5,
                        }}
                    >
                        {additionalData}
                    </StyledTypography>
                </Box>
            );
        }

        return (
            <>
                <RenderScoreByInning
                    dataType={currentView}
                    homeTeamData={(() => {
                        switch (currentView) {
                            case "score":
                                return homeTeamScoreByInning ?? [];
                            case "homeRun":
                                return homeTeamHomeRunByInning ?? [];
                            case "grandSlam":
                                return homeTeamGrandSlamsByInning ?? [];
                            case "outs":
                                return homeTeamOutsByInning ?? [];
                            default:
                                return homeTeamScoreByInning ?? [];
                        }
                    })()}
                    awayTeamData={(() => {
                        switch (currentView) {
                            case "score":
                                return awayTeamScoreByInning ?? [];
                            case "homeRun":
                                return awayTeamHomeRunByInning ?? [];
                            case "grandSlam":
                                return awayTeamGrandSlamsByInning ?? [];
                            case "outs":
                                return awayTeamOutsByInning ?? [];
                            default:
                                return awayTeamScoreByInning ?? [];
                        }
                    })()}
                />
                {!isMobile && (
                    <Box width={186}>
                        <StyledTypography
                            lineClamp={4}
                            mt={0.5}
                            color="text.secondary"
                            variant="body2"
                            sx={{
                                lineHeight: "1.2em",
                                display: "-webkit-box",
                                WebkitBoxOrient: "vertical",
                                whiteSpace: "normal",
                                opacity: 0.5,
                            }}
                        >
                            {additionalData}
                        </StyledTypography>
                    </Box>
                )}
            </>
        );
    };

    return (
        <Box>
            <Stack direction="row" alignItems="center" justifyContent="space-between" gap={0.5}>
                {
                    gameFinished ? (
                        <Stack alignItems="center" direction="row" gap={0.5} >
                            <Typography color="text.secondary" variant="label" textAlign="left">
                                FINAL
                            </Typography>
                        </Stack>
                    ) : (
                        <Stack alignItems="center" direction="row" gap={0.5} >
                            <SensorsIcon fontSize="small" color={isAtTimeout || gameFinished ? "disabled" : "error"} />
                            <StyledTypography variant="body2" color="text.secondary">
                                Live Data
                            </StyledTypography>
                        </Stack>
                    )
                }
                <Stack direction="row" alignItems="center" pl={2}>
                    {
                        !gameFinished && (
                            <StyledTypography variant="caption" color="text.primary" fontWeight="bold">
                                {formattedPeriod}
                            </StyledTypography>
                        )
                    }

                </Stack>

                <Stack direction="row" alignItems="center">
                    <StyledTypography variant="caption" color="text.primary">
                        {headerText}
                    </StyledTypography>
                    <IconButton onClick={() => handleViewChange("prev")} sx={{ py: 0 }}>
                        <KeyboardArrowLeftIcon />
                    </IconButton>
                    <IconButton onClick={() => handleViewChange("next")} sx={{ py: 0 }}>
                        <KeyboardArrowRightIcon />
                    </IconButton>
                </Stack>
            </Stack>

            <Stack direction="row" alignItems="center" mb={2.5} mt={2.5} justifyContent="space-between" width={isMobile ? 325 : 665}>
                <Stack direction="column" gap={1} alignItems="start">
                    <ScoreIndicatorLiveData teamName={awayTeamName} isServing={!isHomeTeamBatting} design={LiveDataDesign.DETAILED} />
                    <ScoreIndicatorLiveData teamName={homeTeamName} isServing={isHomeTeamBatting} design={LiveDataDesign.DETAILED} />
                </Stack>
                <Stack direction="row" gap={1} alignItems="center" >
                    <Stack direction="column" alignItems="center">

                        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }} pt={2.5}>
                            {RenderBases((isHomeTeamBatting ? homeTeamCurrentBases : awayTeamCurrentBases) || [false, false, false])}
                        </Box>

                        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>{RenderOuts(currentOuts)}</Box>

                        <Stack direction="row" alignItems="center">
                            <StyledTypography mt={0.5} variant="label" color="text.primary">
                                {currentBalls}
                            </StyledTypography>
                            <StyledTypography mt={0.5} variant="label" color="text.secondary">
                                -
                            </StyledTypography>
                            <StyledTypography mt={0.5} variant="label" color="text.primary">
                                {currentStrikes}
                            </StyledTypography>
                        </Stack>
                    </Stack>

                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <StyledTypography variant="body2" color="text.secondary">
                            {RenderInningIndicator(parseInt(period, 10), isHomeTeamBatting)}
                        </StyledTypography>
                    </Box>

                </Stack>
                {renderContent()}

            </Stack>
        </Box>
    );
}

export default DetailedBaseballDesign;