import React, { useState } from "react";
import { Box, Stack, IconButton, useTheme, useMediaQuery, Divider, Typography } from "@mui/material";
import StyledTypography from "@/components/StyledComponents/EllipseTypography";
import { TennisLiveDataTypeFragment } from "@/components/Common/GameLiveData/Fragments/TennisLiveDataTypeFragment";
import SensorsIcon from "@mui/icons-material/Sensors";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useTranslations } from "next-intl";
import ScoreIndicatorLiveData from "../ScoreIndicatorLiveData";
import { formatPeriod, isGameFinished } from "../Utils";
import { LiveDataDesign } from "../DesignEnum";

type DetailedTennisDesignProps = {
    gameLiveData: TennisLiveDataTypeFragment;
    player2Name?: string;
    player1Name?: string;
    elapsedTime?: string;
    showAdditionalData?: boolean;
};

const views = ["default", "aces", "breaks"] as const;
type ViewType = typeof views[number];

function DetailedTennisDesign({
    gameLiveData,
    player1Name,
    player2Name,
    elapsedTime = "",
    showAdditionalData = true
}: DetailedTennisDesignProps) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const t = useTranslations("liveData");
    const [currentView, setCurrentView] = useState<ViewType>("default");

    const {
        player1GamesBySet = [],
        player2GamesBySet = [],
        isPlayer1Serving = false,
        player1Points = 0,
        player2Points = 0,
        additionalData = "",
        player1AcesBySet = [],
        player2AcesBySet = [],
        player1BreaksBySet = [],
        player2BreaksBySet = [],
        surfaceType = "",
        period = "",
        player2TieBreaksBySet = [],
        isAtTimeout
    } = gameLiveData;

    // Format data for display
    const player1Score = player1Points?.toString();
    const player2Score = player2Points?.toString();
    const gameFinished = isGameFinished(period);
    const formattedPeriod = formatPeriod(period);

    const getTotal = (data: number[] | null) => (data ?? []).reduce((a, b) => a + b, 0);

    // Navigation between views
    const handleViewChange = (direction: "next" | "prev") => {
        const currentIndex = views.indexOf(currentView);
        const newIndex = direction === "next"
            ? (currentIndex + 1) % views.length
            : (currentIndex - 1 + views.length) % views.length;
        setCurrentView(views[newIndex]);
    };

    // Get view title based on current view
    const getViewTitle = () => {
        switch (currentView) {
            case "default": return t("scores_by_set");
            case "aces": return t("aces_by_set");
            case "breaks": return t("breaks_by_set");
            default: return "";
        }
    };

    // Get current view data
    const getViewData = () => {
        switch (currentView) {
            case "default":
                if (player1GamesBySet && player2GamesBySet) {
                    const setsCount = { player1: 0, player2: 0 };

                    for (let i = 0; i < player1GamesBySet.length; i += 1) {
                        const p1Games = player1GamesBySet[i];
                        const p2Games = player2GamesBySet[i];

                        // Standard set win (6 games with 2-game lead)
                        if (p1Games >= 6 || p2Games >= 6) {
                            if (p1Games >= 6 && p1Games - p2Games >= 2) {
                                setsCount.player1 += 1;
                            } else if (p2Games >= 6 && p2Games - p1Games >= 2) {
                                setsCount.player2 += 1;
                            }
                        }

                        // Tiebreaker rule: If 6-6, the set goes to 7-6
                        if (p1Games === 7 && p2Games === 6) {
                            setsCount.player1 += 1; // Player 1 wins 7-6
                        } else if (p2Games === 7 && p1Games === 6) {
                            setsCount.player2 += 1; // Player 2 wins 7-6
                        }
                    }

                    return {
                        player1Data: player1GamesBySet,
                        player2Data: player2GamesBySet,
                        player1Score: setsCount.player1.toString(),
                        player2Score: setsCount.player2.toString(),
                    };
                }
                return {
                    player1Data: player1GamesBySet,
                    player2Data: player2GamesBySet,
                    player1Score,
                    player2Score,
                };
            case "aces":
                return {
                    player1Data: player1AcesBySet,
                    player2Data: player2AcesBySet,
                    player1Score: getTotal(player1AcesBySet)?.toString(),
                    player2Score: getTotal(player2AcesBySet)?.toString(),
                };
            case "breaks":
                return {
                    player1Data: player1BreaksBySet,
                    player2Data: player2BreaksBySet,
                    player1Score: getTotal(player1BreaksBySet)?.toString(),
                    player2Score: getTotal(player2BreaksBySet)?.toString(),
                };
            default:
                return {
                    player1Data: [],
                    player2Data: [],
                    player1Score: "0",
                    player2Score: "0",
                };
        }
    };

    const {
        player1Data,
        player2Data,
        player1Score: viewPlayer1Score,
        player2Score: viewPlayer2Score
    } = getViewData();

    const playerColumnWidth = isMobile ? 65 : 140;
    const scoreCellWidth = 40;
    const containerWidth = isMobile ? 216 : 340;
    const additionalDataWidth = isMobile ? 95 : 186;
    const setCellWidth = 16;

    const maxSets = Math.max(
        player1Data?.length || 0,
        player2Data?.length || 0,
        player2TieBreaksBySet?.length || 0,
    );

    // Render set headers with data
    const renderSetData = (data: number[] | null, keyPrefix: string) => (
        <Stack direction="row" spacing={isMobile ? 0 : 1} justifyContent="flex-end">
            {Array(maxSets).fill(undefined).map((_, index) => (
                <StyledTypography
                    color="text.primary"
                    variant="body2"
                    key={`${keyPrefix}-${index}`}
                    sx={{ width: setCellWidth, textAlign: 'center' }}
                >
                    {data && data[index] !== undefined ? data[index] : "-"}
                </StyledTypography>
            ))}
        </Stack>
    );

    return (
        <Stack pb={isMobile ? 1 : 2} px={isMobile ? 1 : 2} gap={1}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
                {
                    gameFinished ? (
                        <Stack alignItems="center" direction="row" gap={0.5} >
                            <Typography color="text.secondary" variant="label" textAlign="left">
                                FINAL
                            </Typography>
                        </Stack>
                    ) : (
                        <Stack alignItems="center" direction="row" gap={0.5} >
                            <SensorsIcon fontSize="small" color={isAtTimeout || gameFinished ? "disabled" : "error"} />
                            <StyledTypography variant="body2" color="text.secondary">
                                Live Data
                            </StyledTypography>
                        </Stack>
                    )
                }


                <Stack direction="row" alignItems="center" pl={2}>
                    {
                        !gameFinished && (
                            <StyledTypography variant="caption" color="text.primary" fontWeight="bold">
                                {formattedPeriod} {elapsedTime}
                            </StyledTypography>
                        )
                    }

                </Stack>

                <Stack direction="row" alignItems="center">
                    {!isMobile && (
                        <StyledTypography
                            variant="caption"
                            color="text.secondary"
                            sx={{ width: 120, textAlign: "end" }}
                        >
                            {getViewTitle()}
                        </StyledTypography>
                    )}
                    <IconButton onClick={() => handleViewChange("prev")} sx={{ py: 0 }}>
                        <KeyboardArrowLeftIcon />
                    </IconButton>
                    <IconButton onClick={() => handleViewChange("next")} sx={{ py: 0 }}>
                        <KeyboardArrowRightIcon />
                    </IconButton>
                </Stack>
            </Stack>

            {/* Main content */}
            <Stack direction="row" alignItems="start">

                <Stack direction="row" alignItems="center" width={containerWidth}>
                    {/* Scores by set */}
                    <Stack
                        direction="column"
                        spacing={0}
                        sx={{
                            borderRight: `0.5px solid ${theme.palette.border.primary}`,
                            pr: isMobile ? 0 : 1,
                            flexShrink: 0,
                        }}
                    >
                        {/* Player 1 row */}
                        <Stack direction="row" spacing={1} alignItems="center" mr={1}>
                            {/* Player indicator */}
                            <Stack direction="row" gap={1} flexShrink={0}>
                                <Box width={playerColumnWidth}>
                                    <ScoreIndicatorLiveData
                                        teamName={player1Name}
                                        isServing={isPlayer1Serving}
                                        design={LiveDataDesign.DETAILED}
                                        isFinished={gameFinished}
                                    />
                                </Box>
                                <Box width={scoreCellWidth}>
                                    <ScoreIndicatorLiveData
                                        score={player1Score}
                                        design={LiveDataDesign.DETAILED}
                                        view={currentView}
                                        animateLeft
                                    />
                                </Box>
                            </Stack>
                            {renderSetData(player1Data, 'player1-data')}
                        </Stack>

                        <Stack direction="row" spacing={1} alignItems="center">
                            <Box width={playerColumnWidth + scoreCellWidth + 10} flexShrink={0} lineHeight="12px" />

                            {/* Set numbers */}
                            <Stack direction="row" spacing={isMobile ? 0 : 1} justifyContent="flex-end">
                                {Array(maxSets).fill(undefined).map((_, index) => (
                                    <StyledTypography
                                        color="text.secondary"
                                        variant="label"
                                        key={`header-${index}`}
                                        sx={{ width: setCellWidth, textAlign: 'center' }}
                                    >
                                        {index + 1}
                                    </StyledTypography>
                                ))}
                            </Stack>
                        </Stack>

                        {/* Player 2 row */}
                        <Stack direction="row" spacing={1} alignItems="center" mr={1}>
                            <Stack direction="row" gap={1} flexShrink={0}>
                                <Box width={playerColumnWidth}>
                                    <ScoreIndicatorLiveData
                                        teamName={player2Name}
                                        isServing={!isPlayer1Serving}
                                        design={LiveDataDesign.DETAILED}
                                        isFinished={gameFinished}
                                    />
                                </Box>
                                <Box width={scoreCellWidth}>
                                    <ScoreIndicatorLiveData
                                        score={player2Score}
                                        design={LiveDataDesign.DETAILED}
                                        view={currentView}
                                        animateLeft
                                    />
                                </Box>
                            </Stack>
                            {/* Set data */}
                            {renderSetData(player2Data, 'player2-data')}
                        </Stack>
                    </Stack>

                    {/* Current game score */}
                    <Stack direction="column" gap={1.8} alignItems="start">
                        <ScoreIndicatorLiveData
                            score={viewPlayer1Score}
                            design={LiveDataDesign.DETAILED}
                            view={currentView}
                        />
                        <ScoreIndicatorLiveData
                            score={viewPlayer2Score}
                            design={LiveDataDesign.DETAILED}
                            view={currentView}
                        />
                    </Stack>
                </Stack>

                {showAdditionalData && (
                    <Box width={additionalDataWidth}>
                        <StyledTypography
                            lineClamp={4}
                            mt={0.5}
                            color="text.secondary"
                            variant="body2"
                            sx={{
                                lineHeight: "1.2em",
                                display: "-webkit-box",
                                WebkitBoxOrient: "vertical",
                                whiteSpace: "normal",
                                opacity: 0.5,
                            }}
                        >
                            {additionalData}
                        </StyledTypography>
                    </Box>
                )}
            </Stack>

            {isMobile && (
                <Stack direction="row" justifyContent="start" width="100%" alignItems="center" gap={1}>
                    <StyledTypography
                        color="text.secondary"
                        variant="caption"
                        textAlign="left"
                        lineHeight="1.2em"
                    >
                        Surface: {surfaceType}
                    </StyledTypography>
                    <Divider orientation="vertical" flexItem sx={{ borderColor: theme.palette.border.secondary }} />
                    <StyledTypography
                        color="text.secondary"
                        variant="caption"
                        minWidth={120}
                        textAlign="left"
                        lineHeight="1.2em"
                    >
                        {getViewTitle()}
                    </StyledTypography>
                </Stack>
            )}
        </Stack>
    );
}

export default DetailedTennisDesign;