import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import SensorsIcon from "@mui/icons-material/Sensors";
import { SoccerLiveDataTypeFragment } from "@/components/Common/GameLiveData/Fragments/SoccerLiveDataTypeFragment";
import formatSeconds from "@/utis/formatGameSeconds";
import StyledTypography from "@/components/StyledComponents/EllipseTypography";
import ScoreIndicatorLiveData from "../ScoreIndicatorLiveData";
import ElapsedTime from "../ElapsedTime";
import { formatPeriod, isGameFinished } from "../Utils";
import { LiveDataDesign } from "../DesignEnum";


type CompactSoccerDesignProps = {
    gameLiveData: SoccerLiveDataTypeFragment;
    startTime?: number;
    homeTeamName?: string;
    awayTeamName?: string;
};

function CompactSoccerDesign({ gameLiveData, startTime, homeTeamName, awayTeamName }: CompactSoccerDesignProps) {
    const {
        homeTeamScoreByHalf = [0],
        awayTeamScoreByHalf = [0],
        homeTeamHasPossession = false,
        period = "00",
        gameTimeSeconds = 0,
        isAtTimeout
    } = gameLiveData;

    const homeTeamScore = homeTeamScoreByHalf?.reduce((acc, curr) => acc + (curr ?? 0), 0);
    const awayTeamScore = awayTeamScoreByHalf?.reduce((acc, curr) => acc + (curr ?? 0), 0);
    const isHomeTeamPossession = homeTeamHasPossession;

    const formattedGameTime = formatSeconds(gameTimeSeconds);
    const gameFinished = isGameFinished(period);
    const formattedPeriod = formatPeriod(period);

    return (
        <>
            {
                gameFinished ? (
                    <Box position="absolute" top={0} left={0} sx={{ transform: "translate(30%, -20%)" }}>
                        <Typography color="text.secondary" variant="smallText" textAlign="left">
                            FINAL
                        </Typography>
                    </Box>
                ) : (
                    <Box position="absolute" top={0} left={0} sx={{ transform: "translate(5%, 0%)" }}>
                        <SensorsIcon color={isAtTimeout ? "disabled" : "error"} sx={{
                            height: "16px",
                        }} />
                    </Box>
                )
            }
            <Box>
                <Stack direction="row" gap={1} alignItems="center" mt={0.5}>
                    <Stack direction="column" spacing={1} alignItems="end" sx={{ flexGrow: 1 }}>
                        <ScoreIndicatorLiveData score={awayTeamScore?.toString()} isServing={!isHomeTeamPossession} design={LiveDataDesign.COMPACT} isFinished={gameFinished} />
                        <ScoreIndicatorLiveData score={homeTeamScore?.toString()} isServing={isHomeTeamPossession} design={LiveDataDesign.COMPACT} isFinished={gameFinished} />
                    </Stack>

                    <Stack direction="column" alignItems="start" justifyContent="flex-end" mt="14px" gap={0.5}>
                        <StyledTypography variant="caption"
                            color={gameFinished ? "text.secondary" : "text.primary"}
                            fontSize={gameFinished ? 10 : 14}
                            lineHeight={gameFinished ? "8px" : "14px"}
                        >{formattedPeriod}</StyledTypography>
                        <Box>
                            {!gameFinished && <StyledTypography variant="caption" fontSize={14} lineHeight="8px">{formattedGameTime}</StyledTypography>}
                            <ElapsedTime startTime={startTime} isgameLive />
                        </Box>
                    </Stack>
                </Stack>


            </Box>
        </>
    );
};

export default CompactSoccerDesign;
