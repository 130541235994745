import { TeamType } from "@/components/Common/Fragments/ToolsSubscriptionFragment";

export default function formatTeamNameByLeague(team: TeamType | undefined, leagueName: string) {
  if (!team) {
    return "";
  }
  if (leagueName === "EURO_LEAGUE_BB") {
    return team.name;
  }
  if (leagueName === "EUFA_CHAMPIONS") {
    return team.name;
  }
  if (leagueName === "LA_LLGA") {
    return team.name;
  }
  if (leagueName === "ENGLISH_PREMIER") {
    return team.name;
  }
  if (leagueName === "FRENCH_LIGUE_1") {
    return team.name;
  }
  if (leagueName === "GERMAN_BUNDESLIGA") {
    return team.name;
  }
  if (leagueName === "ITALIAN_SERIA_A") {
    return team.name;
  }
  if (!team.city) {
    return team.name;
  }
  return `${team.city} ${team.name}`;
}
