import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import SensorsIcon from "@mui/icons-material/Sensors";
import { BaseballLiveDataTypeFragment } from "@/components/Common/GameLiveData/Fragments/BaseballLiveDataTypeFragment";
import { useTheme } from "@/theme/ThemeContext";
import StyledTypography from "@/components/StyledComponents/EllipseTypography";
import ScoreIndicatorLiveData from "../ScoreIndicatorLiveData";
import ElapsedTime from "../ElapsedTime";
import { LiveDataDesign } from "../DesignEnum";
import { isGameFinished } from "../Utils";

type CompactBaseballDesignProps = {
    gameLiveData: BaseballLiveDataTypeFragment;
    startTime: number;
};


// Utility function to render three bases as rhomboids
function RenderBases(bases: boolean[]) {
    const theme = useTheme();
    if (!bases || bases.length === 0) return null;

    return (
        <Box sx={{ position: "relative", left: "50%", transform: "translate(-50%)" }}>
            {/* First rhomboid */}
            <Box
                sx={{
                    position: "absolute",
                    width: "7px",
                    height: "7px",
                    background: bases[2] ? theme.palette.secondary.main : theme.palette.divider,
                    transform: "rotate(-45deg)",
                    top: "-12px",
                    left: "-10px", // Adjust positioning
                }}
            />
            {/* Second rhomboid */}
            <Box
                sx={{
                    position: "absolute",
                    width: "7px",
                    height: "7px",
                    background: bases[1] ? theme.palette.secondary.main : theme.palette.divider,
                    transform: "rotate(-45deg)",
                    top: "-19px",
                    left: "-3px", // Centered horizontally
                }}
            />
            {/* Third rhomboid */}
            <Box
                sx={{
                    position: "absolute",
                    width: "7px",
                    height: "7px",
                    background: bases[0] ? theme.palette.secondary.main : theme.palette.divider,
                    transform: "rotate(-45deg)",
                    top: "-12px", // Positioned below the first two
                    left: "4px", // Align with first rhomboid
                }}
            />
        </Box>
    );
}

function RenderOuts(outs: number[]) {
    const theme = useTheme();
    const latestOuts = outs?.[outs.length - 1] || 0; // Default to 0 if the array is empty

    return (
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            {Array.from({ length: 3 }, (_, index) => (
                <Box
                    key={index}
                    sx={{
                        width: "5px",
                        height: "5px",
                        borderRadius: "50%",
                        backgroundColor: index < latestOuts ? theme.palette.secondary.main : theme.palette.divider,
                        margin: "0 2px",
                    }}
                />
            ))}
        </Box>
    );
}

function RenderInningIndicator(inning: number, isHomeTeamBatting: boolean, gameFinished: boolean) {
    const theme = useTheme();

    // Calculate top triangle color
    let topTriangleColor = theme.palette.divider;
    if (!gameFinished && !isHomeTeamBatting) {
        topTriangleColor = theme.palette.secondary.main;
    }

    // Calculate bottom triangle color
    let bottomTriangleColor = theme.palette.divider;
    if (!gameFinished && isHomeTeamBatting) {
        bottomTriangleColor = theme.palette.secondary.main;
    }

    return (
        <Stack direction="column" alignItems="center" sx={{ minWidth: "15px" }}>
            {/* Top Triangle */}
            <Box
                sx={{
                    width: 0,
                    height: 0,
                    borderLeft: "3px solid transparent",
                    borderRight: "3px solid transparent",
                    borderBottom: "6px solid",
                    borderBottomColor: topTriangleColor,
                }}
            />
            <StyledTypography variant="caption" color="text.primary" minHeight="15px">
                {!gameFinished && inning}
            </StyledTypography>
            {/* Bottom Triangle */}
            <Box
                sx={{
                    width: 0,
                    height: 0,
                    borderLeft: "3px solid transparent",
                    borderRight: "3px solid transparent",
                    borderTop: "6px solid",
                    borderTopColor: bottomTriangleColor,
                }}
            />
        </Stack>
    );
}

function CompactBaseballDesign({ gameLiveData, startTime }: CompactBaseballDesignProps) {
    const {
        awayTeamScoreByInning = [0],
        homeTeamScoreByInning = [0],
        isHomeTeamBatting = false,
        period = "00",
        currentStrikes = 0,
        currentBalls = 0,
        awayTeamCurrentBases = [false, false, false],
        homeTeamCurrentBases = [false, false, false],
        awayTeamOutsByInning = [0],
        homeTeamOutsByInning = [0],
        isAtTimeout
    } = gameLiveData;

    const homeTeamScore = homeTeamScoreByInning?.reduce((acc, curr) => acc + (curr ?? 0), 0);
    const awayTeamScore = awayTeamScoreByInning?.reduce((acc, curr) => acc + (curr ?? 0), 0);

    const currentOuts = (isHomeTeamBatting ? awayTeamOutsByInning : homeTeamOutsByInning) || [];

    const gameFinished = isGameFinished(period);

    return (
        <>
            {
                gameFinished ? (
                    <Box position="absolute" top={0} left={0} sx={{ transform: "translate(30%, -20%)" }}>
                        <Typography color="text.secondary" variant="smallText" textAlign="left">
                            FINAL
                        </Typography>
                    </Box>
                ) : (
                    <Box position="absolute" top={0} left={0} sx={{ transform: "translate(5%, 0%)" }}>
                        <SensorsIcon color={isAtTimeout ? "disabled" : "error"} sx={{
                            height: "16px",
                        }} />
                    </Box>
                )
            }
            <Box>
                <Stack
                    direction="row"
                    spacing={1}
                    alignItems="center"
                    justifyContent="center"
                    mt={0.5}
                    mr={0.5}
                    sx={{ width: "80px", overflow: "hidden" }}
                >
                    <Stack direction="column" gap={1} alignItems="end">
                        <ScoreIndicatorLiveData score={homeTeamScore?.toString()} isServing={false} design={LiveDataDesign.COMPACT} />
                        <ScoreIndicatorLiveData score={awayTeamScore?.toString()} isServing={false} design={LiveDataDesign.COMPACT} />
                    </Stack>

                    <Stack direction="column" alignItems="center">
                        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }} pt={2.5}>
                            {RenderBases((isHomeTeamBatting ? homeTeamCurrentBases : awayTeamCurrentBases) || [false, false, false])}
                        </Box>

                        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>{RenderOuts(currentOuts)}</Box>

                        {
                            gameFinished ? (
                                <StyledTypography variant="supScript" color="text.secondary" mt={0.5}>
                                    Finished
                                </StyledTypography>
                            ) : (
                                <Stack direction="row" alignItems="center" sx={{
                                    opacity: gameFinished ? 0.5 : 1,
                                }}>
                                    <StyledTypography mt={0.5} variant="caption" color="text.primary">
                                        {currentBalls}
                                    </StyledTypography>
                                    <StyledTypography mt={0.5} variant="caption" color="text.secondary">
                                        -
                                    </StyledTypography>
                                    <StyledTypography mt={0.5} variant="caption" color="text.primary">
                                        {currentStrikes}
                                    </StyledTypography>
                                </Stack>

                            )
                        }
                    </Stack>
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <StyledTypography variant="caption" color="text.secondary">
                            {RenderInningIndicator(parseInt(period, 10), isHomeTeamBatting, gameFinished)}
                        </StyledTypography>
                    </Box>
                </Stack>

                <Stack sx={{ alignItems: "center" }}>
                    <ElapsedTime startTime={startTime} isgameLive />
                </Stack>
            </Box>
        </>
    );
};

export default CompactBaseballDesign;
