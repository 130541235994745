import { League } from "@/components/Common/Enums/LeagueEnum";
import { Sports } from "@/components/Common/Enums/SportsEnum";
import { useAppSelector } from "@/store/store";
import React from "react";
import { Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import useGetCurrentlyFromLiveData from "@/hooks/useGetCurrentlyFromLiveData";
import { useTranslations } from "next-intl";
import formatTeamNameByLeague from "@/utis/formatTeamNameByLeague";
import TennisLiveData from "./TennisLiveData/TennisLiveData";
import BaseBallLiveData from "./BaseBallLiveData/BaseBallLiveData";
import SoccerLiveData from "./SoccerLiveData/SoccerLiveData";
import FootballLiveData from "./FootBallLiveData/FootBallLiveData";
import IceHockeyLiveData from "./IceHockeyLiveData/IceHockeyLiveData";
import BasketballLiveData from "./BasketBallLiveData/BasketBallLiveData";
import { LiveDataDesign } from "./DesignEnum";
import { BetMarketTypeEnum } from "../Common/Enums/BetMarketTypeEnum";

type CurrentlySportLiveDataProps = {
  gameId: string;
  league: League;
  betMarketType: BetMarketTypeEnum;
  teamId?: number;
  playerId?: number;
};

function CurrentlySportLiveData(props: CurrentlySportLiveDataProps) {
  const t = useTranslations("common");
  const theme = useTheme();
  const currently = useGetCurrentlyFromLiveData(
    props.gameId,
    props.league,
    props.betMarketType,
    props.teamId,
    props.playerId,
  );
  if (currently === null || currently === undefined) return null;
  return (
    <Stack direction="row" alignItems="end" sx={{ lineHeight: "12px !important" }}>
      <Typography
        variant="caption"
        color="text.secondary"
        sx={{ lineHeight: "12px !important", textTransform: "uppercase" }}
      >
        {t("currently")}:&nbsp;&nbsp;
      </Typography>
      <Typography
        variant="number"
        color="text.primary"
        sx={{ fontSize: theme.typography.caption.fontSize, fontWeight: 400 }}
      >
        {currently}
      </Typography>
    </Stack>
  );
}

type SportLiveDataProps = {
  gameId: string;
  leagueId: League;
  startTime?: number;
  design?: LiveDataDesign;
  betMarketType?: string | undefined;
  conditionTeamId?: number | undefined;
  conditionPlayerId?: number | undefined;
  showAdditionalData?: boolean;
  isMobile?: boolean;
};

function SportLiveData({
  gameId,
  leagueId,
  startTime,
  design,
  betMarketType,
  conditionTeamId,
  conditionPlayerId,
  showAdditionalData = true,
}: SportLiveDataProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  // Load required data from Redux store
  const sportsData = useAppSelector((state) => state.constantsReducer.sports) || [];
  const gameLiveDataList = useAppSelector((state) => state.gameLiveDataReducer);
  const gameData = useAppSelector((state) => state.gameDataCacheReducer.gameData[gameId]);
  const gameLiveData = gameLiveDataList[gameId];

  // Determine sport type from league
  const sportForLeague = sportsData.find((sport) =>
    sport.leagues.some((lg) => lg.leagueEnum === leagueId)
  );
  const sportEnum = sportForLeague?.sportEnum;
  const isTennis = sportEnum === Sports.TENNIS;

  // Get team/player names with proper null handling
  const homeTeamAbbreviation = gameData?.homeTeam?.abbreviations?.[0];
  const awayTeamAbbreviation = gameData?.awayTeam?.abbreviations?.[0];
  const homeTeamName = formatTeamNameByLeague(gameData?.homeTeam, leagueId);
  const awayTeamName = formatTeamNameByLeague(gameData?.awayTeam, leagueId);

  // Create sport-specific props
  const baseProps = {
    gameLiveData,
    startTime,
    design,
    showAdditionalData,
  };

  // Build the final props object based on sport type
  const sportProps = isTennis
    ? {
      ...baseProps,
      // For tennis, use consistent player names regardless of mobile/desktop
      player1Name: awayTeamName,
      player2Name: homeTeamName,
    }
    : {
      ...baseProps,
      // For team sports, conditionally use abbreviations on mobile
      ...(isMobile
        ? {
          homeTeamName: homeTeamAbbreviation,
          awayTeamName: awayTeamAbbreviation,
        }
        : {
          homeTeamName,
          awayTeamName,
        }),
      // Add abbreviations for components that need them
      homeTeamAbbr: homeTeamAbbreviation,
      awayTeamAbbr: awayTeamAbbreviation,
    };

  // Create a mapping of sport to live data components
  const sportComponentMap: Record<Sports, React.ElementType | undefined> = {
    [Sports.FOOTBALL]: FootballLiveData,
    [Sports.SOCCER]: SoccerLiveData,
    [Sports.BASEBALL]: BaseBallLiveData,
    [Sports.TENNIS]: TennisLiveData,
    [Sports.ICE_HOCKEY]: IceHockeyLiveData,
    [Sports.BASKETBALL]: BasketballLiveData,
  };

  // Get the appropriate component for the current sport
  const SportComponent = sportEnum ? sportComponentMap[sportEnum] : undefined;

  if (!SportComponent) {
    return null;
  }

  return (
    <>
      <SportComponent {...sportProps} />
      {betMarketType?.includes("PLAYER_PROP") && (
        <CurrentlySportLiveData
          gameId={gameId}
          league={leagueId}
          betMarketType={betMarketType as BetMarketTypeEnum}
          teamId={conditionTeamId}
          playerId={conditionPlayerId}
        />
      )}
    </>
  );
}

export default SportLiveData;