import { ApolloError } from "@apollo/client";
import { useCallback } from "react";
import { useFilterContextSelector } from "@/components/Common/Filters/FilterContext";
import { useDispatch } from "react-redux";
import { localStoreAction } from "@/store/localStore";
import { useTranslations } from "next-intl";
import useUpdatePageFilter from "./useUpdatePageFilter";

function useHandleWsError() {
  const t = useTranslations("common");
  const filterState = useFilterContextSelector((ctx) => ctx.filterState);
  const updatePageFilter = useUpdatePageFilter();
  const dispatch = useDispatch();

  const handleWsError = useCallback(
    (err: ApolloError) => {
      const regex = /Unable to convert '(.*?)' to 'BetMarketTypeEnumTypeTwo/;
      const match = err.message.match(regex);
      if (match) {
        const newBetMarketInfoFilter = filterState.betMarketInfo?.filter((bmi) => bmi !== match[1]);
        updatePageFilter({ betMarketInfo: newBetMarketInfoFilter });
        dispatch(localStoreAction.showErrorPopup(t("removedBetMarketError")));
        setTimeout(() => {
          localStoreAction.showErrorPopup(undefined);
          window.location.reload();
        }, 3000);
      }
    },
    [dispatch, filterState.betMarketInfo, t, updatePageFilter],
  );

  return handleWsError;
}

export default useHandleWsError;
