import React, { useState } from "react";
import { Box, Stack, IconButton, useTheme, useMediaQuery, Typography } from "@mui/material";
import StyledTypography from "@/components/StyledComponents/EllipseTypography";
import { FootballLiveDataTypeFragment } from "@/components/Common/GameLiveData/Fragments/FootballLiveDataTypeFragment";
import formatSeconds from "@/utis/formatGameSeconds";
import SensorsIcon from "@mui/icons-material/Sensors";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useTranslations } from "next-intl";
import ScoreIndicatorLiveData from "../ScoreIndicatorLiveData";
import { formatPeriod, isGameFinished, determineYardsToGo, isInRedZone } from "../Utils";
import { LiveDataDesign } from "../DesignEnum";

type DetailedFootballDesignProps = {
    gameLiveData: FootballLiveDataTypeFragment;
    homeTeamName?: string;
    awayTeamName?: string;
};

const views = ["default", "touchdowns", "fieldGoals", "safeties", "turnovers", "drives", "passes", "coachChallenges", "timeouts"] as const;
type ViewType = typeof views[number];

function DetailedFootballDesign({
    gameLiveData,
    homeTeamName,
    awayTeamName
}: DetailedFootballDesignProps) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const t = useTranslations("liveData");
    const [currentView, setCurrentView] = useState<ViewType>("default");

    const {
        awayTeamTotalPoints = [0],
        homeTeamTotalPoints = [0],
        homeTeamHasPossession = false,
        period = "00",
        gameTimeSeconds = 0,
        activeDown = "00",
        yardsToNextDown = "00",
        yardsDistance = "00",
        additionalData = "",
        awayTeamTouchdowns = [0],
        homeTeamTouchdowns = [0],
        awayTeamFieldGoals = [0],
        homeTeamFieldGoals = [0],
        awayTeamSafeties = [0],
        homeTeamSafeties = [0],
        awayTeamTurnovers = [0],
        homeTeamTurnovers = [0],
        awayTeamDrives = [0],
        homeTeamDrives = [0],
        awayTeamPasses = [0],
        homeTeamPasses = [0],
        awayTeamCoachChallenges = [0],
        homeTeamCoachChallenges = [0],
        awayTeamTimeouts = [0],
        homeTeamTimeouts = [0],
        isAtTimeout
    } = gameLiveData;

    // Compute derived values
    const homeTeamScore = homeTeamTotalPoints?.reduce((acc, curr) => acc + (curr ?? 0), 0);
    const awayTeamScore = awayTeamTotalPoints?.reduce((acc, curr) => acc + (curr ?? 0), 0);
    const formattedGameTime = formatSeconds(gameTimeSeconds);
    const isHomeTeamPossession = homeTeamHasPossession;
    const formattedYardsToGo = determineYardsToGo(yardsDistance?.toString() || "", homeTeamName, awayTeamName, isHomeTeamPossession);
    const redZoneStatus = isInRedZone(yardsDistance?.toString() || "");

    const gameFinished = isGameFinished(period);
    const formattedPeriod = formatPeriod(period);


    const getTotal = (data: number[] | null) => (data ?? []).reduce((a, b) => a + b, 0);

    // Navigation between views
    const handleViewChange = (direction: "next" | "prev") => {
        const currentIndex = views.indexOf(currentView);
        const newIndex = direction === "next"
            ? (currentIndex + 1) % views.length
            : (currentIndex - 1 + views.length) % views.length;
        setCurrentView(views[newIndex]);
    };

    // Get view title based on current view
    const getViewTitle = () => {
        switch (currentView) {
            case "default": return t("scores_by_quarter");
            case "touchdowns": return t("touchdowns");
            case "fieldGoals": return t("field_goals");
            case "safeties": return t("safeties");
            case "turnovers": return t("turnovers");
            case "drives": return t("drives");
            case "passes": return t("passes");
            case "coachChallenges": return t("coaches_challenges");
            case "timeouts": return t("timeouts");
            default: return "";
        }
    };

    // Get current view data
    const getViewData = () => {
        switch (currentView) {
            case "default":
                return {
                    awayData: awayTeamTotalPoints,
                    homeData: homeTeamTotalPoints,
                    awayScore: awayTeamScore,
                    homeScore: homeTeamScore,
                };
            case "touchdowns":
                return {
                    awayData: awayTeamTouchdowns,
                    homeData: homeTeamTouchdowns,
                    awayScore: getTotal(awayTeamTouchdowns),
                    homeScore: getTotal(homeTeamTouchdowns),
                };
            case "fieldGoals":
                return {
                    awayData: awayTeamFieldGoals,
                    homeData: homeTeamFieldGoals,
                    awayScore: getTotal(awayTeamFieldGoals),
                    homeScore: getTotal(homeTeamFieldGoals),
                };
            case "safeties":
                return {
                    awayData: awayTeamSafeties,
                    homeData: homeTeamSafeties,
                    awayScore: getTotal(awayTeamSafeties),
                    homeScore: getTotal(homeTeamSafeties),
                };
            case "turnovers":
                return {
                    awayData: awayTeamTurnovers,
                    homeData: homeTeamTurnovers,
                    awayScore: getTotal(awayTeamTurnovers),
                    homeScore: getTotal(homeTeamTurnovers),
                };
            case "drives":
                return {
                    awayData: awayTeamDrives,
                    homeData: homeTeamDrives,
                    awayScore: getTotal(awayTeamDrives),
                    homeScore: getTotal(homeTeamDrives),
                };
            case "passes":
                return {
                    awayData: awayTeamPasses,
                    homeData: homeTeamPasses,
                    awayScore: getTotal(awayTeamPasses),
                    homeScore: getTotal(homeTeamPasses),
                };
            case "coachChallenges":
                return {
                    awayData: awayTeamCoachChallenges,
                    homeData: homeTeamCoachChallenges,
                    awayScore: getTotal(awayTeamCoachChallenges),
                    homeScore: getTotal(homeTeamCoachChallenges),
                };
            case "timeouts":
                return {
                    awayData: awayTeamTimeouts,
                    homeData: homeTeamTimeouts,
                    awayScore: getTotal(awayTeamTimeouts),
                    homeScore: getTotal(homeTeamTimeouts),
                };
            default:
                return {
                    awayData: [],
                    homeData: [],
                    awayScore: 0,
                    homeScore: 0,
                };
        }
    };

    const { awayData, homeData, awayScore, homeScore } = getViewData();

    // Define reusable UI styles
    const teamColumnWidth = isMobile ? 52 : 176;
    const containerWidth = isMobile ? 205 : 340;
    const additionalDataWidth = isMobile ? 135 : 186;
    const periodCellWidth = 16;

    // Render quarter headers with data
    const renderQuarterData = (data: number[] | null, keyPrefix: string) => (
        <Stack direction="row" spacing={1} justifyContent="flex-end">
            {Array(4).fill(undefined).map((_, index) => (
                <StyledTypography
                    color="text.primary"
                    variant="body2"
                    key={`${keyPrefix}-${index}`}
                    sx={{ width: periodCellWidth, textAlign: 'center' }}
                >
                    {data && data[index] !== undefined ? data[index] : "-"}
                </StyledTypography>
            ))}
        </Stack>
    );

    return (
        <Stack pb={isMobile ? 1 : 2} px={isMobile ? 1 : 2} gap={1}>
            {/* Header section */}
            <Stack direction="row" alignItems="center" justifyContent="space-between">
                {/* Live indicator */}
                {
                    gameFinished ? (
                        <Stack alignItems="center" direction="row" gap={0.5} >
                            <Typography color="text.secondary" variant="label" textAlign="left">
                                FINAL
                            </Typography>
                        </Stack>
                    ) : (
                        <Stack alignItems="center" direction="row" gap={0.5} >
                            <SensorsIcon fontSize="small" color={isAtTimeout || gameFinished ? "disabled" : "error"} />
                            <StyledTypography variant="body2" color="text.secondary">
                                Live Data
                            </StyledTypography>
                        </Stack>
                    )
                }

                {/* Game time */}
                <Stack direction="row" alignItems="center" pl={2}>
                    <StyledTypography variant="caption" color="text.primary" fontWeight="bold">
                        {formattedPeriod} {!gameFinished && formattedGameTime}
                    </StyledTypography>
                </Stack>

                {/* View controls */}
                <Stack direction="row" alignItems="center">
                    {!isMobile && (
                        <StyledTypography
                            variant="caption"
                            color="text.secondary"
                            sx={{ width: 120, textAlign: "end" }}
                        >
                            {getViewTitle()}
                        </StyledTypography>
                    )}
                    <IconButton onClick={() => handleViewChange("prev")} sx={{ py: 0 }}>
                        <KeyboardArrowLeftIcon />
                    </IconButton>
                    <IconButton onClick={() => handleViewChange("next")} sx={{ py: 0 }}>
                        <KeyboardArrowRightIcon />
                    </IconButton>
                </Stack>
            </Stack>

            {/* Game situation information */}
            {!gameFinished && activeDown && (
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                        border: redZoneStatus ? `1px solid ${theme.palette.error.main}` : undefined,
                        borderRadius: 1,
                        py: 0.5,
                        px: 1
                    }}
                >
                    <StyledTypography
                        variant="caption"
                        color={redZoneStatus ? "error" : "text.primary"}
                        fontWeight={redZoneStatus ? "bold" : "regular"}
                    >
                        {`${activeDown}${t("down")} & ${yardsToNextDown} • ${formattedYardsToGo}`}
                    </StyledTypography>
                </Stack>
            )}

            {/* Main content */}
            <Stack direction="row" alignItems="start">
                <Stack direction="row" alignItems="center" width={containerWidth}>
                    <Stack
                        direction="column"
                        spacing={0}
                        sx={{
                            borderRight: `0.5px solid ${theme.palette.border.primary}`,
                            pr: 1,
                            flexShrink: 0,
                        }}
                    >
                        {/* Away team row */}
                        <Stack direction="row" spacing={1} alignItems="center">
                            <Box width={teamColumnWidth} flexShrink={0}>
                                <ScoreIndicatorLiveData
                                    teamName={awayTeamName}
                                    isServing={!homeTeamHasPossession}
                                    design={LiveDataDesign.DETAILED}
                                />
                            </Box>
                            {renderQuarterData(awayData, 'away-data')}
                        </Stack>

                        {/* Quarter headers */}
                        <Stack direction="row" spacing={1} alignItems="center">
                            <Box width={teamColumnWidth} flexShrink={0} lineHeight="12px" />
                            <Stack direction="row" spacing={1} justifyContent="flex-end">
                                {["1", "2", "3", "4"].map((header, index) => (
                                    <StyledTypography
                                        color="text.secondary"
                                        variant="label"
                                        key={`header-${index}`}
                                        sx={{ width: periodCellWidth, textAlign: 'center' }}
                                    >
                                        {header}
                                    </StyledTypography>
                                ))}
                            </Stack>
                        </Stack>

                        {/* Home team row */}
                        <Stack direction="row" spacing={1} alignItems="center">
                            <Box width={teamColumnWidth} flexShrink={0}>
                                <ScoreIndicatorLiveData
                                    teamName={homeTeamName}
                                    isServing={homeTeamHasPossession}
                                    design={LiveDataDesign.DETAILED}
                                />
                            </Box>
                            {renderQuarterData(homeData, 'home-data')}
                        </Stack>
                    </Stack>

                    {/* Total scores */}
                    <Stack direction="column" gap={1.5} alignItems="start">
                        <ScoreIndicatorLiveData
                            score={awayScore?.toString()}
                            design={LiveDataDesign.DETAILED}
                            view={currentView}
                        />
                        <ScoreIndicatorLiveData
                            score={homeScore?.toString()}
                            design={LiveDataDesign.DETAILED}
                            view={currentView}
                        />
                    </Stack>
                </Stack>

                {/* Additional data */}
                <Box width={additionalDataWidth}>
                    <StyledTypography
                        lineClamp={4}
                        mt={0.5}
                        color="text.secondary"
                        variant="body2"
                        sx={{
                            lineHeight: isMobile ? "1em" : "1.2em",
                            display: "-webkit-box",
                            WebkitBoxOrient: "vertical",
                            whiteSpace: "normal",
                            opacity: 0.5,
                        }}
                    >
                        {additionalData}
                    </StyledTypography>
                </Box>
            </Stack>

            {/* Mobile view title */}
            {isMobile && (
                <Stack direction="row" justifyContent="start" width="100%" ml="58px">
                    <StyledTypography
                        color="text.secondary"
                        variant="caption"
                        minWidth={120}
                        textAlign="left"
                        lineHeight="1.2em"
                    >
                        {getViewTitle()}
                    </StyledTypography>
                </Stack>
            )}
        </Stack>
    );
}

export default DetailedFootballDesign;