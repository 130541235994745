import React, { useEffect, useRef, useState } from 'react';
import { Box, Stack } from '@mui/material';

interface FreeThrowIndicatorProps {
    freeThrowsPending: number;
    freeThrowsMissed: number;
    freeThrowsScored: number;
}

function FreeThrowIndicator({ freeThrowsPending, freeThrowsMissed, freeThrowsScored }: FreeThrowIndicatorProps) {
    const [showIndicators, setShowIndicators] = useState(false);
    const [results, setResults] = useState<Array<'pending' | 'missed' | 'scored'>>([]);

    // Refs to track previous values
    const prevPendingRef = useRef<number>(freeThrowsPending);
    const prevMissedRef = useRef<number>(freeThrowsMissed);
    const prevScoredRef = useRef<number>(freeThrowsScored);

    useEffect(() => {
        // Trigger indicator only on a transition from 0 to >0 for pending throws.
        if (prevPendingRef.current === 0 && freeThrowsPending > 0) {
            setShowIndicators(true);
            setResults(Array(freeThrowsPending).fill('pending'));
        }

        if (showIndicators) {
            // Calculate the delta changes; assume the values are monotonically increasing
            const missedDelta = freeThrowsMissed - prevMissedRef.current;
            const scoredDelta = freeThrowsScored - prevScoredRef.current;

            // Update the result for pending free throws sequentially based on the deltas
            setResults(prevResults => {
                const updated = [...prevResults];
                // Process missed updates first.
                for (let i = 0; i < missedDelta; i += 1) {
                    const index = updated.findIndex(r => r === 'pending');
                    if (index !== -1) {
                        updated[index] = 'missed';
                    }
                }
                // Process scored updates.
                for (let i = 0; i < scoredDelta; i += 1) {
                    const index = updated.findIndex(r => r === 'pending');
                    if (index !== -1) {
                        updated[index] = 'scored';
                    }
                }
                return updated;
            });

            // When pending goes back to 0, remove indicators after a delay.
            if (freeThrowsPending === 0) {
                setTimeout(() => {
                    setShowIndicators(false);
                    setResults([]);
                }, 2000);
            }
        }

        // Update our previous trackers.
        prevPendingRef.current = freeThrowsPending;
        prevMissedRef.current = freeThrowsMissed;
        prevScoredRef.current = freeThrowsScored;
    }, [freeThrowsPending, freeThrowsMissed, freeThrowsScored, showIndicators]);

    if (!showIndicators) return null;

    return (
        <Stack direction="row" spacing={0.2} width="100%" justifyContent="center">
            {results.map((result, index) => {
                let iconColor;
                if (result === "pending") {
                    iconColor = "transparent";
                } else if (result === "missed") {
                    iconColor = "error.main";
                } else {
                    iconColor = "success.main";
                }

                return (
                    <Box
                        key={index}
                        sx={{
                            width: 7,
                            height: 7,
                            backgroundColor: iconColor,
                            border: "0.5px solid white",
                            borderRadius: "50%",
                        }}
                    />
                );
            })}
        </Stack>
    );
};

export default FreeThrowIndicator;